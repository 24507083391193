
import * as gaia from "../gaia.js";
import {buildProduct} from "../productUtils.js";
import {tr} from "../../ux/translations.js";
import * as sources from "../sources.js";
import WW3primaryBuilder from "./ww3-primary.js";
import * as palettes from "../../palette/palettes.js";
import {scalarProduct} from "../scalarProduct.js";
import {length} from "../../util/math.js";
import {keep, ø} from "../../util/objects.js";
import * as utc from "../../util/utc.js";
import * as clock from "../../util/clock.js";

/**
 * @param {Object} time_cursor either the date parts or the string "current"
 * @param {Object?} offset the time offset from the normal GFS time (e.g., {minute: 90} for 3hr time avg)
 * @returns {Object} the best matching date parts.
 */
function ww3ValidTime(time_cursor, offset) {
    let parts = time_cursor === "current" ?
        keep(utc.parts(clock.now()), "year", "month", "day", "hour") :  // use current time
        ø(time_cursor);
    // round down to the nearest three-hour block
    parts.hour = Math.floor(parts.hour / 3) * 3;
    parts.minute = 0;
    if (offset) {
        parts = utc.normalize(utc.add(parts, offset));
    }
    return parts;
}

function ww3PathParts(attr, offset) {
    const validTime = ww3ValidTime(attr.time_cursor, offset);
    if (attr.time_cursor === "current") {
        return {dir: "current", stamp: "current", validTime};
    }
    return {dir: utc.print(validTime, "{YYYY}/{MM}/{DD}"), stamp: utc.print(validTime, "{hh}{mm}"), validTime};
}

function ww3Path(attr, type) {
    const parts = ww3PathParts(attr);
    const file = [parts.stamp, type, "wave", "30m"].filter(e => e !== undefined).join("-") + ".epak";
    return gaia.ww3Url(parts.dir + "/" + file);
}

export function createPrimaryWavesLayer(attr) {
    return buildProduct({
        type: "primary_waves",
        descriptionHTML: {
            name: tr("Peak Wave Period"),
            qualifier: "",
        },
        sourceHTML: sources.ww3,
        paths: [ww3Path(attr, "primary")],
        validTime: function() {
            return ww3ValidTime(attr.time_cursor);
        },
        builder: function(file) {
            return WW3primaryBuilder(file);
        },
        unitDescriptors: {
            "s": {scalarize: length, precision: 1, convention: "into"},
        },
        scale: palettes.primaryWaves(),
        particles: {velocityScale: 1/612, maxIntensity: 12, waves: true}
    });
}

export function createSignificantWaveHeightLayer(attr) {
    return buildProduct({
        type: "significant_wave_height",
        descriptionHTML: {
            name: tr("Significant Wave Height"),
            qualifier: "",
        },
        sourceHTML: sources.ww3,
        paths: [ww3Path(attr, "sig_height")],
        validTime: function() {
            return ww3ValidTime(attr.time_cursor);
        },
        builder: function(file) {
            // "Significant_height_of_combined_wind_waves_and_swell_surface"
            return scalarProduct(file, /Significant_height/, {hasMissing: true});
        },
        unitDescriptors: {
            "m":  {convert: x => x,                   precision: 2},
            "ft": {convert: x => x * 100 / 2.54 / 12, precision: 1},
        },
        scale: palettes.htsgw(),
    });
}
