
import {model as sharedConfig} from "../framework/sharedState.js";
import {model as sessionConfig} from "../framework/sessionState.js";
import {productsFor} from "../product/products.js";
import {loadProducts} from "../network/fetcher.js";
import {MINUTE} from "../util/consts.js";
import * as utc from "../util/utc.js";
import {ø} from "../util/objects.js";

// Some hacky stuff to ensure only one download can be in progress at a time.
let downloadsInProgress = 0;
// Currently active task to auto refresh data.
let autoRefresher = NaN;

import {isHeadless} from "../util/context.js";
let headlessDownloads = Math.floor(Math.random() * 30) + 20;
let frozenAttr;

export function buildLayers(
    layerManagerAgent,
    primaryLayerAgent,
    overlayLayerAgent,
    activeLayerAgent,
    report,
    cancel,
) {
    report.status("Downloading...");
    console.time("build layers");
    downloadsInProgress++;

    const attr = frozenAttr || ø(sharedConfig.getAll(), sessionConfig.getAll());
    if (isHeadless() && headlessDownloads-- < 0) {
        frozenAttr = attr;
    }

    return Promise.all(productsFor(attr)).then(products => {

        return loadProducts(products, cancel).then(products => {
            primaryLayerAgent.submit(products[0]);
            overlayLayerAgent.submit(products[1]);
            activeLayerAgent.submit(products[1] ?? products[0]);
        }).catch(err => {
            report.error(err);
            primaryLayerAgent.submit(products[0]);
            overlayLayerAgent.submit(products[1]);
            activeLayerAgent.submit(products[1] ?? products[0]);
        });

    }).finally(() => {
        downloadsInProgress--;
        console.timeEnd("build layers");

        // Re-download "current" data periodically.
        // UNDONE: this doesn't work for OSCAR data because the catalog is not refreshed.
        clearInterval(autoRefresher);
        autoRefresher = sharedConfig.get("time_cursor") === "current" ?
            setInterval(
                () => layerManagerAgent.submit(function() {
                    return buildLayers(
                        layerManagerAgent,
                        primaryLayerAgent,
                        overlayLayerAgent,
                        activeLayerAgent,
                        report,
                        this.cancel
                    );
                }),
                60 * MINUTE) :
            NaN;
    });
}

/**
 * Modifies the configuration to navigate to the chronologically next or previous data layer.
 */
export function navigate(layer, op) {
    if (downloadsInProgress > 0) {
        console.log("Download in progress--ignoring nav request.");
        return;
    }
    const next = layer?.[op]?.();
    if (next) {
        sharedConfig.set("time_cursor", utc.normalize(next));
    }
}
