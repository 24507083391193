
import * as d3 from "../lib/d3.js";
import {seq} from "../util/seq.js";
import {debounce} from "../lib/underscore.js";


export function computeViewbox() {
    const {innerWidth: width, innerHeight: height} = window;
    return {xMin: 0, yMin: 0, xMax: width - 1, yMax: height - 1, width, height};
}

/**
 * @param viewboxAgent
 * @returns the newly computed viewbox
 */
export function defineViewbox(viewboxAgent) {

    function updateViewbox() {
        const viewbox = computeViewbox();
        viewboxAgent.submit(viewbox);
        return viewbox;
    }

    d3.select(window).on(seq`resize.?`, debounce(updateViewbox, 1000));

    // we assume the agent has already been initialized with the initial viewbox
    // updateViewbox();
}
