
import * as palette from "./palette.js";

export default function(resolution = 1000) {

    const bounds = [-11, 11];  // units: Kelvin
    const segments = [
        [-11.0,  [255, 255, 255]],
        [-3,    [  7, 252, 254]],
        [-1.5,  [ 66,  42, 253]],
        [-0.75, [ 34,  55, 134]],
        [ 0,    [  0,   0,   6]],
        [ 0.75, [134,  55,  34]],
        [ 1.5,  [253,  14,  16]],
        [ 3.0,  [254, 252,   0]],
        [ 11.0,  [255, 255, 255]],
    ];
    return palette.buildScaleFromSegments(bounds, segments, resolution);

}
