
import * as palette from "./palette.js";

export default function(resolution = 1000) {

    const bounds = [0, 15];  // units: m
    const segments = [
        [ 0, [  8,  29,  88]],
        [ 1, [ 37,  52, 148]],
        [ 2, [ 34,  94, 168]],
        [ 3, [ 29, 145, 192]],
        [ 4, [ 65, 182, 196]],
        [ 5, [127, 205, 187]],
        [ 6, [199, 233, 180]],
        [ 7, [237, 248, 177]],
        [ 8, [254, 204,  92]],
        [10, [253, 141,  60]],
        [12, [240,  59,  32]],
        [14, [189,   0,  38]],
    ];
    return palette.buildScaleFromSegments(bounds, segments, resolution);

}
