import * as palette from "./palette.js";


export function meanTemp(resolution = 1000) {

    const bounds = [270 - 273.15, 304.65 - 273.15];  // units: C
    const segments = [
        [270    - 273.15, [255, 255, 255]],
        [271.25 - 273.15, [255, 255, 255]],  // -1.9 C sea water freeze
        [271.30 - 273.15, [ 15,   4, 168]],
        [273.15 - 273.15, [ 15,  54, 208]],  // 0 C fresh water freeze
        [273.25 - 273.15, [ 15,  54, 188]],
        [275.65 - 273.15, [ 15,   4, 168]],  // lower boundary for cool currents
        [281.65 - 273.15, [ 24, 132,  14]],  // upper boundary for cool currents
        [291.15 - 273.15, [247, 251,  59]],  // lower boundary for warm currents
        [295    - 273.15, [235, 167,   0]],
        [299.65 - 273.15, [245,   0,  39]],  // minimum needed for tropical cyclone formation
        [303    - 273.15, [ 87,  17,   0]],
        [304.65 - 273.15, [238,   0, 242]],
    ];
    return palette.buildScaleFromSegments(bounds, segments, resolution);

}

export function tempAnomaly(resolution = 1000) {

    const bounds = [-10, 10];  // units: C
    const segments = [
        [-10,  [0,75,30]],
        [-6,   [0,150,0]],
        [-0.5, [161,217,155]],
        [0,    [255,255,255]],
        [0.5,  [255,175,100]],
        [6,    [255,111,0]],
        [10,   [166,50,0]],
    ];
    return palette.buildScaleFromSegments(bounds, segments, resolution);

}

export function meanSalinity(resolution = 1000) {

    const bounds = [34, 36];  // units: PSU
    // const bounds = [32, 39];  // units: PSU
    const segments = [
        [ 34.0,  [0, 155, 210]],
        [ 34.3,  [2, 169, 210]],
        [ 34.7,  [150, 200, 220]],
        [ 34.94,  [200, 215, 220]],
        [ 35.0,  [230, 220, 200]],
        [ 35.5,  [235, 205, 140]],
        [ 36,  [240, 190, 70]],
        // [ 32.0,  [66, 99, 51]],
        // [ 33.6,  [108, 145, 81]],
        // [ 35.2,  [149, 191, 110]],
        // [ 36.0,  [205, 154, 176]],
        // [ 37.6,  [173, 104, 132]],
        // [ 39.0,  [140, 55, 107]],
    ];
    return palette.buildScaleFromSegments(bounds, segments, resolution);

}

export function salinityAnomaly(resolution = 1000) {

    const bounds = [-0.8, 0.8];  // units: PSU
    const segments = [
        [ -0.8,  [7, 62, 94]],
        [ -0.6/2,  [27, 88, 121]],
        [ -0.4/2,  [47, 113, 148]],
        [ -0.2/4,  [74, 138, 175 ]],
        [ -0.1/4,  [101, 163, 201]],
        [  0.1/4,  [249, 163, 120]],
        [  0.2/4,  [222, 135, 100]],
        [  0.4/2,  [194, 106, 80]],
        [  0.6/2,  [166, 77, 40]],
        [  0.8,  [138, 48, 0]],
    ];
    return palette.buildScaleFromSegments(bounds, segments, resolution);

}
