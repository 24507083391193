
import {ø} from "../../util/objects.js";
import * as utc from "../../util/utc.js";
import {isIFrameContext, isKioskContext} from "../../util/context.js";
import * as gaia from "../gaia.js";
import {describeSurface, newProduct, resolveValidTime} from "../productUtils.js";
import {tr} from "../../ux/translations.js";
import * as sources from "../sources.js";
import {buildGFSWind} from "./gfs-wind.js";
import * as palettes from "../../palette/palettes.js";
import {scalarProduct} from "../scalarProduct.js";
import {buildGFSWindPowerDensity} from "./gfs-wpd.js";
import {mulvec2, length} from "../../util/math.js";
import {createUnitDescriptors} from "../../util/units.js";


/**
 * @param attr
 * @param {String} type
 * @param {String?} z_level
 * @returns {String}
 */
function gfsPath(attr, type, z_level) {
    const {time_cursor, hd_enabled} = attr;
    const validTime = resolveValidTime(time_cursor, {hour: 3});
    const dir = time_cursor === "current" ? "current" : utc.print(validTime, "{YYYY}/{MM}/{DD}");
    const stamp = time_cursor === "current" ? "current" : utc.print(validTime, "{hh}{mm}");

    const validTimestamp = utc.date(validTime);
    const format = validTimestamp >= new Date("2014/11/24") ? ".epak" : ".json";
    let res = "0.5";
    if (validTimestamp < new Date("2015/03/11") || (isIFrameContext() && !isKioskContext())) {
        res = "1.0";
    } else if (validTimestamp > new Date("2016/06/22") && hd_enabled) {
        res = "0.25";
    }

    const surface = z_level === "surface" ? "surface" : z_level !== undefined ? "isobaric" : undefined;
    const level = z_level === "surface" ? "level" : z_level !== undefined ? z_level : undefined;
    const file = [stamp, type, surface, level, "gfs", res].filter(e => e !== undefined).join("-") + format;
    return gaia.gfsUrl(dir + "/" + file);
}

function newGFSProduct(attr) {
    const validTime = resolveValidTime(attr.time_cursor, {hour: 3});
    const stepper = step => function() {
        return resolveValidTime(utc.add(this.validTime(), step), {hour: 3});
    };
    return {
        sourceHTML: sources.gfs,
        validTime: () => ø(validTime),
        prev2: stepper({day:  -1}),
        prev1: stepper({hour: -3}),
        next1: stepper({hour: +3}),
        next2: stepper({day:  +1}),
        navLabels: {
            prev2: tr("-1 day"),
            prev1: tr("-3 hours"),
            next1: tr("+3 hours"),
            next2: tr("+1 day"),
        },
        // UNDONE: move this to newProduct() when everywhere
        navigate(step) {
            if (step < -1) return this.prev2();
            if (step > +1) return this.next2();
            if (step < 0) return this.prev1();
            if (step > 0) return this.next1();
            return this.validTime();
        },
    };
}

export function createWindLayer(attr) {
    return ø(newProduct(), newGFSProduct(attr), {
        type: "wind",
        descriptionHTML: {
            name: tr("Wind"),
            qualifier: ` @ ${describeSurface(attr)}`,
        },
        paths: [gfsPath(attr, "wind", attr.z_level)],
        builder: buildGFSWind,
        unitDescriptors: createUnitDescriptors({
            "km/h": {convert: uv => mulvec2(uv, 3.6),      scalarize: length, precision: 0, convention: "into"},
            "m/s":  {                                      scalarize: length, precision: 1, convention: "into"},
            "kn":   {convert: uv => mulvec2(uv, 1.943844), scalarize: length, precision: 0, convention: "into"},
            "mph":  {convert: uv => mulvec2(uv, 2.236936), scalarize: length, precision: 0, convention: "into"},
        }),
        scale: palettes.wind(),
        particles: {velocityScale: 1/100, maxIntensity: 15},
    });
}

export function createTempLayer(attr) {
    const selector = /Temperature/;  // "Temperature_isobaric", "Temperature_height_above_ground"
    return ø(newProduct(), newGFSProduct(attr), {
        type: "temp",
        descriptionHTML: {
            name: tr("Temp"),
            qualifier: ` @ ${describeSurface(attr)}`,
        },
        paths: [gfsPath(attr, "temp", attr.z_level)],
        builder: file => scalarProduct(file, selector, {hasMissing: false, legacyName: "Temperature"}),
        unitDescriptors: createUnitDescriptors({
            "°C": {convert: x => x - 273.15,       precision: 1},
            "°F": {convert: x => x * 9/5 - 459.67, precision: 1},
            "K":  {convert: x => x,                precision: 1},
        }),
        scale: palettes.temp(),
    });
}

export function createRHLayer(attr) {
    const selector = /Relative_humidity/;  // "Relative_humidity_isobaric", "Relative_humidity_height_above_ground"
    return ø(newProduct(), newGFSProduct(attr), {
        type: "relative_humidity",
        descriptionHTML: {
            name: tr("Relative Humidity"),
            qualifier: ` @ ${describeSurface(attr)}`,
        },
        paths: [gfsPath(attr, "relative_humidity", attr.z_level)],
        builder: file => scalarProduct(file, selector, {hasMissing: false}),
        unitDescriptors: createUnitDescriptors({
            "%": {convert: x => x, precision: 0},
        }),
        scale: palettes.rh(),
    });
}

export function createAirDensLayer(attr) {
    return ø(newProduct(), newGFSProduct(attr), {
        type: "air_density",
        descriptionHTML: {
            name: tr("Air Density"),
            qualifier: ` @ ${describeSurface(attr)}`,
        },
        paths: [gfsPath(attr, "air_density", attr.z_level)],
        builder: file => scalarProduct(file, /air_density/, {hasMissing: false}),
        unitDescriptors: createUnitDescriptors({
            "kg/m^3": {convert: x => x, precision: 2},
        }),
        scale: palettes.airdens(),
    });
}

export function createWPDLayer(attr) {
    const windProduct = createWindLayer(attr);
    const airdensProduct = createAirDensLayer(attr);
    return ø(newProduct(), newGFSProduct(attr), {
        type: "wind_power_density",
        descriptionHTML: {
            name: tr("Instantaneous Wind Power Density"),
            qualifier: ` @ ${describeSurface(attr)}`,
        },
        paths: [windProduct.paths[0], airdensProduct.paths[0]],
        builder: buildGFSWindPowerDensity,
        unitDescriptors: createUnitDescriptors({
            "kW/m^2": {convert: x => x / 1000, precision: 1},
            "W/m^2":  {convert: x => x,        precision: 0},
        }),
        scale: palettes.wpd(),
    });
}

export function createMiseryIndexLayer(attr) {
    const perceived = ` ${tr("(feels like)")}`;
    return ø(newProduct(), newGFSProduct(attr), {
        type: "misery_index",
        descriptionHTML: {
            name: tr("Misery Index"),
            qualifier: ` @ ${describeSurface(attr)}`,
        },
        paths: [gfsPath(attr, "misery_index")],
        builder: file => scalarProduct(file, /misery_index/, {hasMissing: false}),
        unitDescriptors: createUnitDescriptors({
            "°C": {html: "°C" + perceived, convert: x => x - 273.15,       precision: 1},
            "°F": {html: "°F" + perceived, convert: x => x * 9/5 - 459.67, precision: 1},
             "K": {html:  "K" + perceived, convert: x => x,                precision: 1},
        }),
        scale: palettes.misery(),
    });
}

export function createTCWLayer(attr) {
    const selector = /Cloud_water/;  // "Cloud_water_entire_atmosphere", "Cloud_water_entire_atmosphere_single_layer"
    return ø(newProduct(), newGFSProduct(attr), {
        type: "total_cloud_water",
        descriptionHTML: {
            name: tr("Total Cloud Water"),
            qualifier: "",
        },
        paths: [gfsPath(attr, "total_cloud_water")],
        builder: file => scalarProduct(file, selector, {hasMissing: false, legacyName: "Cloud_water"}),
        unitDescriptors: createUnitDescriptors({
            "kg/m^2": {convert: x => x, precision: 3},
        }),
        scale: palettes.tcw(),
    });
}

export function createTPWLayer(attr) {
    // "Precipitable_water_entire_atmosphere", "Precipitable_water_entire_atmosphere_single_layer"
    const selector = /Precipitable_water/;
    return ø(newProduct(), newGFSProduct(attr), {
        type: "total_precipitable_water",
        descriptionHTML: {
            name: tr("Total Precipitable Water"),
            qualifier: "",
        },
        paths: [gfsPath(attr, "total_precipitable_water")],
        builder: file => scalarProduct(file, selector, {hasMissing: false, legacyName: "Precipitable_water"}),
        unitDescriptors: createUnitDescriptors({
            "kg/m^2": {convert: x => x, precision: 3},
        }),
        scale: palettes.tpw(),
    });
}

export function createMSLPLayer(attr) {
    const selector = /Pressure_reduced_to_MSL/;  // Pressure_reduced_to_MSL_msl
    return ø(newProduct(), newGFSProduct(attr), {
        type: "mean_sea_level_pressure",
        descriptionHTML: {
            name: tr("Mean Sea Level Pressure"),
            qualifier: "",
        },
        paths: [gfsPath(attr, "mean_sea_level_pressure")],
        builder: file => scalarProduct(file, selector, {hasMissing: false, legacyName: "Pressure_reduced_to_MSL"}),
        unitDescriptors: createUnitDescriptors({
            "hPa":  {convert: x => x / 100,           precision: 0},
            "mmHg": {convert: x => x / 133.322387415, precision: 0},
            "inHg": {convert: x => x / 3386.389,      precision: 1},
        }),
        scale: palettes.mslp(),
    });
}

export function create3HPALayer(attr) {
    return ø(newProduct(), newGFSProduct(attr), {
        type: "precip_3hr",
        descriptionHTML: {
            name: tr("3-hour Precipitation Accumulation"),
            qualifier: "",
        },
        paths: [gfsPath(attr, "precip_3hr")],
        builder: file => scalarProduct(file, /precip_accumulation_3hr/, {hasMissing: false}),
        unitDescriptors: createUnitDescriptors({
            "mm":     {convert: x => x,        precision: 1},
            "in":     {convert: x => x / 25.4, precision: 2},
            "kg/m^2": {convert: x => x,        precision: 1},
        }),
        alpha: {single: 160, animated: 160},
        scale: palettes.precip(),
    });
}

export function createCAPELayer(attr) {
    return ø(newProduct(), newGFSProduct(attr), {
        type: "cape",
        descriptionHTML: {
            name: tr("Convective Available Potential Energy from Surface"),
            qualifier: "",
        },
        paths: [gfsPath(attr, "cape")],
        builder: file => scalarProduct(file, /Convective_available_potential_energy_surface/, {hasMissing: false}),
        unitDescriptors: createUnitDescriptors({
            "J/kg": {convert: x => x, precision: 0},
        }),
        alpha: {single: 160, animated: 140},
        scale: palettes.cape(),
    });
}
