/*
 * polyfills: polyfill global object with missing ES6+ features.
 */

import "setimmediate";
import "promise-polyfill/src/polyfill.js";

if (!Math.sign) {
    // Needed for IE11.
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/sign
    Math.sign = function(x) {
        // If x is NaN, the result is NaN.
        // If x is -0, the result is -0.
        // If x is +0, the result is +0.
        // If x is negative and not -0, the result is -1.
        // If x is positive and not +0, the result is +1.
        return ((x > 0) - (x < 0)) || +x;
    }
}

if (!Object.entries) {
    // Needed for IE 11.
    Object.entries = o => Object.keys(o).map(key => [key, o[key]]);
}

if (!Object.values) {
    // Needed for IE 11.
    Object.values = o => Object.keys(o).map(key => o[key]);
}

if (!Object.assign) {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign#Polyfill
    // Needed for IE 11.
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, "assign", {
        value: function assign(target, varArgs) { // .length of function is 2
            if (target === null || target === undefined) {
                throw new TypeError("Cannot convert undefined or null to object");
            }

            const to = Object(target);

            for (let index = 1; index < arguments.length; index++) {
                const nextSource = arguments[index];

                if (nextSource !== null && nextSource !== undefined) {
                    for (const nextKey in nextSource) {
                        // Avoid bugs when hasOwnProperty is shadowed
                        if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                            to[nextKey] = nextSource[nextKey];
                        }
                    }
                }
            }
            return to;
        },
        writable: true,
        configurable: true
    });
}
