
import * as palette from "./palette.js";

export function variantA(Δ, resolution = 1000) {

    const bounds = [360 + Δ, 470 + Δ];  // units: CO2 Surface Concentration ppmv
    const stops = [
        {color: [  0,   0,   0], mode: "hcl", p: bounds[0]},
        {color: [100,   0,   0], mode: "hcl", p: 400 + Δ},
        {color: [164,  36,   0], mode: "hcl", p: 405 + Δ},
        {color: [255, 220, 140], mode: "hcl", p: 420 + Δ},
        {color: [255, 255, 255], mode: "hcl", p: 450 + Δ},
        {color: [  0, 210, 255], mode: "hcl", p: bounds[1]}
    ];

    const x = stops[3].p, y = stops[4].p, z = stops[1].p;
    let interpolators = palette.interpolatorsFrom(stops);
    interpolators = palette.smooth(interpolators, 0, [z-2, z+2]);
    interpolators = palette.smooth(interpolators, 3, [x-2, x+2]);
    interpolators = palette.smooth(interpolators, 5, [y-2, y+2]);
    return palette.buildScaleFromInterpolators(bounds, interpolators, resolution);

}

// GEOS-5 was upgraded at 2018-07-11T04:30Z, drastically changing the CO2sc data and making the previous palette look
// terrible. Let's use a different palette adjusted to this upgrade.

export function variantB(resolution = 1000) {

    const bounds = [380, 510];  // units: CO2 Surface Concentration ppmv
    const stops = [
        {color: [  0,   0,   0], mode: "hcl", p: bounds[0]},
        {color: [100,   0,   0], mode: "hcl", p: 412},
        {color: [164,  36,   0], mode: "hcl", p: 418},
        {color: [255, 220, 140], mode: "hcl", p: 438},
        {color: [  0, 220, 180], mode: "hcl", p: 485},
        {color: [  0, 255, 255], mode: "hcl", p: bounds[1]},
    ];

    const x = stops[3].p, y = stops[4].p, z = stops[1].p;
    let interpolators = palette.interpolatorsFrom(stops);
    interpolators = palette.smooth(interpolators, 0, [z-3, z+2]);
    interpolators = palette.smooth(interpolators, 3, [x-3, x+3]);
    interpolators = palette.smooth(interpolators, 5, [y-3, y+3]);
    return palette.buildScaleFromInterpolators(bounds, interpolators, resolution);

}
