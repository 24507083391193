
import * as palette from "./palette.js";

export default function(resolution = 10) {

    const bounds = [0, 1.5];  // units: kg/m^3
    const segments = [
        [0,   [  0,   0,   0]],
        [1.5, [255, 255, 255]],
    ];
    return palette.buildScaleFromSegments(bounds, segments, resolution);

}
