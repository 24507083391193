
import texture2D from "./texture2D.frag";
import bilinearWrap from "./bilinearWrap.frag";
import scalar from "./scalar.frag";
import vector from "./vector.frag";

export function shaderSourceTexture2D() {
    return texture2D;
}

export function shaderSourceBilinearWrap() {
    return bilinearWrap;
}

export function scalarSource() {
    return scalar;
}

export function vectorSource() {
    return vector;
}
