
import {isDevContext} from "../util/context.js";

const GAIA_HOME = "https://gaia.nullschool.net";
const PANGAEA_HOME = "https://gaia.nullschool.net";

function resolve(path) {
    const host = isDevContext() ? "" : PANGAEA_HOME;
    return host + path;
}

export function gfsUrl(path) {
    return resolve("/data/gfs/" + path);
}

export function rtgsstUrl(path) {
    return resolve("/data/rtgsst/" + path);
}

export function ww3Url(path) {
    return resolve("/data/ww3/" + path);
}

export function geosUrl(path) {
    return resolve("/data/geos/" + path);
}

export function oscarUrl(path) {
    return resolve("/data/oscar/" + path);
}

export function camsUrl(path) {
    return resolve("/data/cams/" + path);
}

export function ovationUrl(path) {
    return resolve("/data/ovation/" + path);
}

export function taraUrl(path) {
    return resolve("/data/tara/" + path);
}

export function argoUrl(path) {
    return resolve("/data/argo/" + path);
}

export function ostiaUrl(path) {
    return resolve("/data/ostia/" + path);
}
