
import * as utc from "../../util/utc.js";
import * as ostia from "../ostia/ostia.js";
import * as rtg from "../rtg/rtg.js";
import {buildProduct} from "../productUtils.js";
import {tr} from "../../ux/translations.js";
import * as palettes from "../../palette/palettes.js";


const OSTIA_SWITCH_DATE = utc.date({year: 2019, month: 12, day: 15});

function isOstia(date) {
    return date === "current" || utc.date(date) >= OSTIA_SWITCH_DATE;
}

function sstValidTime(time_cursor) {
    return isOstia(time_cursor) ? ostia.ostiaValidTime(time_cursor) : rtg.rtgValidTime(time_cursor);
}

function sstStep(validTime, step) {
    return isOstia(validTime) ? ostia.ostiaStep(validTime, step) : rtg.rtgStep(validTime, step);
}

function sstPath(attr, name) {
    return isOstia(attr.time_cursor) ? ostia.ostiaPath(attr, name) : rtg.rtgPath(attr, name);
}

function sstProduct(attr, file, name) {
    return isOstia(attr.time_cursor) ? ostia.ostiaProduct(attr, file, name) : rtg.rtgProduct(file, name);
}

export function createSSTLayer(attr) {
    const type = "sea_surface_temp";
    return buildProduct({
        type: type,
        descriptionHTML: {
            name: tr("Sea Surface Temperature"),
            qualifier: "",
        },
        paths: [sstPath(attr, type)],
        validTime: function() {
            return sstValidTime(attr.time_cursor);
        },
        navigate: function(step) {
            return sstStep(this.validTime(), step);
        },
        builder: function(file) {
            return sstProduct(attr, file, type);
        },
        unitDescriptors: {
            "°C": {convert: x => x - 273.15,       precision: 1},
            "°F": {convert: x => x * 9/5 - 459.67, precision: 1},
            "K":  {convert: x => x,                precision: 1},
        },
        scale: palettes.sst(),
    });
}

export function createSSTALayer(attr) {
    const type = "sea_surface_temp_anomaly";
    return buildProduct({
        type: type,
        descriptionHTML: {
            name: tr("Sea Surface Temperature Anomaly"),
            qualifier: "",
        },
        paths: [sstPath(attr, type)],
        validTime: function() {
            return sstValidTime(attr.time_cursor);
        },
        navigate: function(step) {
            return sstStep(this.validTime(), step);
        },
        builder: function(file) {
            return sstProduct(attr, file, type);
        },
        unitDescriptors: {
            "°C": {convert: x => x,       precision: 1},
            "°F": {convert: x => x * 9/5, precision: 1},
            "K":  {convert: x => x,       precision: 1},
        },
        scale: palettes.ssta(),
    });
}
