
import * as palette from "./palette.js";

export default function(resolution = 1000) {

    const bounds = [0.0003578, 0.0003770];  // units: CO2 Bulk Mixing Ratio (Column Mass/ps) -- or CO2 column load??
    const stops = [
        {color: [  0,   0,   0], mode: "hcl", p: bounds[0]},
        {color: [100,   0,   0], mode: "hcl", p: 0.0003650},
        {color: [164,  36,   0], mode: "hcl", p: 0.0003675},
        {color: [255, 220, 140], mode: "hcl", p: 0.0003710},
        {color: [255, 255, 255], mode: "hcl", p: bounds[1]}
    ];

    let interpolators = palette.interpolatorsFrom(stops);
    interpolators = palette.smooth(interpolators, 2, [0.0003700, 0.0003720]);
    return palette.buildScaleFromInterpolators(bounds, interpolators, resolution);

}
