import {truncateEntries} from "./objects.js";
import {isFunction} from "../lib/underscore.js";
import {choke} from "./functions.js";

/**
 * Send a Google Tag event.
 *
 * https://developers.google.com/tag-platform/devguides/events
 * https://developers.google.com/tag-platform/gtagjs/reference/events
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events
 *
 * @param {string} eventName event name (max 40 chars)
 * @param {object?} eventParams event parameters (max 25 entries, keys max 40 chars, values max 100 chars)
 */
export function gtagEvent(eventName, eventParams = {}) {
    const name = eventName.substring(0, 40);
    const params = truncateEntries(eventParams, 40, 100);
    // console.log("** event", name, params);
    if (isFunction(window.gtag)) {
        window.gtag("event", name, params);
        cleanup();
    }
}

const cleanup = choke(() => {
    // HACK: periodically shorten dataLayer, otherwise it grows forever
    const {dataLayer} = window;
    if (dataLayer.length >= 100) {
        dataLayer.splice(0, dataLayer.length / 2);
    }
}, 5000);
