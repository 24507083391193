
import "./polyfills.js";
import "./ux/inert.js";

import * as d3 from "./lib/d3.js";
import {defer} from "./util/functions.js";
import * as denylist from "./util/denylist.js";
import {earth} from "./earth.js";
import {isAppContext, isDevContext, isIFrameContext} from "./util/context.js";
import {tryURL} from "./util/strings.js";
import {gtagEvent} from "./util/gtag.js";
// import {createLogBridge} from "./bridge.js";

// require("./clock").calibration({server: "/"}); /*calibration({fixed: "2015-11-24T00:00Z"});*/

function launch(bridge = {}) {
    window.earth = earth({bridge});
}

void function main() {

    if (isDevContext()) {
        console.log("dev mode enabled");
    }

    // work-around for iOS 10 bug: https://github.com/metafizzy/flickity/issues/457
    window.addEventListener("touchmove", () => {});

    const init = {
        referring_host: tryURL(document.referrer)?.host ?? "",
        is_iframe: isIFrameContext(),
    };

    gtagEvent("init", init);

    if (isIFrameContext()) {
        if (denylist.contains(document.referrer)) {
            denylist.deny();
            return;
        }
    }

    if (isAppContext()) {
        d3.select("#details").classed("hidden", true);
        window.main = function(bridge) {
            defer(() => launch(bridge));
        };
        return;
    }

    // launch(createLogBridge());
    launch();

}();
