
import * as _ from "../lib/underscore.js";
import {request as d3request} from "../lib/d3.js";
import {decodeEpak} from "../codec/decoder.js";
import {clamp} from "../util/math.js";
import {gtagEvent} from "../util/gtag.js";


function reportFetch(url) {
    try {
        const {host, pathname} = new URL(url, window.location.href);
        gtagEvent("fetch", {fetch_host: host, fetch_pathname: pathname});
    } catch(e) {
    }
}

/**
 * @param {string} resource
 * @param {Function} resolve
 * @param {Function} reject
 * @param {ProgressEvent|Error} err
 * @param {*} result
 * @returns {*}
 */
function xhrResolver(resource, resolve, reject, err, result) {
    const {target} = err || {};
    return target ?
        target.status ?
            reject({status: target.status, message: target.statusText, resource: resource, error: err}) :
            reject({status: -1, message: `Cannot load ${resource}: ${err}`, resource: resource, error: err}) :
        resolve(result);
}

/**
 * Returns a promise for a JSON resource (URL) fetched via XHR. If the load fails, the promise rejects with an
 * object describing the reason: {status: http-status-code, message: http-status-text, resource:}.
 * @returns {Promise}
 */
export function loadJson(resource) {
    reportFetch(resource);
    return new Promise((resolve, reject) => {
        d3request(resource)
            .mimeType("application/json")
            .response(xhr => JSON.parse(xhr.responseText))
            .get((err, result) => xhrResolver(resource, resolve, reject, err, result));
    });
}

/**
 * Same as loadJson but returns a singleton promise for each URL.
 */
export const loadJsonOnce = _.memoize(loadJson);

/**
 * Parses headers string into a map.
 *
 * "Content-Type: Foo\r\nContent-Length: 1234"  ->  {"content-type": ["foo"], "content-length": ["1234"]}
 *
 * @param {string} s unparsed headers string.
 * @returns {Object} map of header name to array of values (usually just one element, but possibly more).
 */
function parseHeaders(s) {
    const result = Object.create(null);
    (s || "").split("\n").forEach(line => {
        const i = line.indexOf(":");
        if (i < 0) return;
        const key = line.substr(0, i).trim().toLowerCase();
        const value = line.substr(i + 1).trim();
        result[key] = (result[key] || []).concat(value);
    });
    return result;
}

/**
 * @param {ProgressEvent} e
 * @returns {number}
 */
function computeProgress(e) {
    let total = e.total;
    if (!e.lengthComputable) {
        const headers = parseHeaders(e.target.getAllResponseHeaders());
        total = (headers["x-uncompressed-size"] || [])[0];
    }
    return total ? clamp(e.loaded / total, 0, 1) : NaN;
}

/**
 * Returns a promise for an EPAK resource (URL) fetched via XHR. If the load fails, the promise rejects
 * with an object describing the reason: {status: http-status-code, message: http-status-text, resource:}.
 * @returns {Promise}
 */
export function loadEpak(resource) {
    reportFetch(resource);
    return new Promise((resolve, reject) => {
        d3request(resource)
            .responseType("arraybuffer")
            .response(req => decodeEpak(req.response))  // UNDONE: promise swallows decoding exceptions
            // .on("progress", /** @type {ProgressEvent} */ e => {
            //     const pct = computeProgress(e);
            //     if (pct) {
            //         console.log(resource, Math.round(pct * 100));
            //     } else {
            //         console.log(resource, e.loaded);
            //     }
            // })
            .get((err, result) => xhrResolver(resource, resolve, reject, err, result));
    });
}

function doLoad(path) {
    return /\.epak([/?#]|$)/.test(path) ? loadEpak(path) : loadJson(path);
}

function loadProduct(product, cancel) {
    return !product || cancel.requested ?
        Promise.resolve(undefined) :
        Promise.all(product.paths.map(doLoad)).then(files => {
            return cancel.requested ? undefined : Object.assign(product, product.builder.apply(product, files));
        });
}

export function loadProducts(products, cancel) {
    return Promise.all(products.map(product => loadProduct(product, cancel)));
}
