
import * as d3 from "../lib/d3.js";

/**
 * @param {Function} callback
 * @returns {Function} a function that invokes <i>callback</i> with the <i>this</i> context passed as the first argument
 */
export function hoist(callback) {
    return function() {
        return callback(this, ...arguments);
    };
}

/**
 * @param {Function} callback
 * @returns {Function} a function that invokes <i>callback</i> with the <i>this</i> context wrapped as a D3 selection
 *          and passed as the first argument
 */
export function lift(callback) {
    return function() {
        return callback(d3.select(this), ...arguments);
    };
}

/**
 * @param {Function} callback
 * @returns {Function} a function that invokes <i>callback</i> only when the <i>this</i> context is a DOM element that
 *          is enabled (defined as not having <i>aria-disabled=true</i>)
 */
export function whenEnabled(callback) {
    return function() {
        if (d3.select(this).attr("aria-disabled") !== "true") {
            return callback.apply(this, arguments);
        }
    };
}

/**
 * @param {string} name
 * @returns {Function}
 */
export function isNamed(name) {
    return function() {
        return this.dataset.name === name;
    }
}

/*
function isVisible() {
    return this.offsetWidth > 0 || this.offsetHeight > 0 || this.getClientRects().length > 0;
}
*/

/**
 * @param {Element} element
 * @returns {boolean} true if the element is inert or has an inert ancestor.
 */
export function isInert(element) {
    for (let cursor = element; cursor !== null; cursor = cursor.parentElement) {
        if (cursor.hasAttribute("inert")) {
            return true;
        }
    }
    return false;
}

/**
 * @param {Element} element
 * @returns {boolean} true if the element or an ancestor is hidden, inert, or cloaked.
 */
export function isHiddenOrInertOrCloaked(element) {
    for (let cursor = element; cursor !== null; cursor = cursor.parentElement) {
        if (cursor.hidden || cursor.hasAttribute("inert") || cursor.classList.contains("cloaked")) {
            return true;
        }
    }
    return false;
}
