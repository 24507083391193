
import * as utc from "../util/utc.js";
import {ø} from "../util/objects.js";
import {seq} from "../util/seq.js";
import * as _ from "../lib/underscore.js";
import * as globes from "../engine/globes.js";
import {overlayTypes} from "../product/products.js";
import {createModel} from "./model.js";
import * as d3 from "../lib/d3.js";

function fragmentToState(param, level) {
    const mode = param === "wind" ? "air" : param;
    switch (level) {
        case "currents": return {mode, animation_type: "currents", z_level: "surface"};
        case "level": return {mode, animation_type: "wind", z_level: "surface"};
        case "waves": return {mode, animation_type: "primary_waves", z_level: "surface"};
    }
    return {mode, animation_type: "wind", z_level: level};
}

function stateToFragment(mode, animation_type, z_level) {
    const param = mode === "air" ? "wind" : mode;
    switch (animation_type) {
        case "currents": return [param, "surface", "currents"];
        case "primary_waves": return [param, "primary", "waves"];
    }
    switch (z_level) {
        case "surface": return [param, "surface", "level"];
    }
    return [param, "isobaric", z_level];
}

function fragmentToOrientation(s) {
    const parts = s?.split(",") ?? [];

    const λ = -parts[0];
    const φ = -parts[1];
    const rotate = (λ === λ && φ === φ) ? [λ, φ] : undefined;

    const k = +parts[2];
    const scale = k === k ? k : undefined;

    return ø({rotate, scale});
}

function orientationToFragment(o) {
    const l = -(o?.rotate?.[0]);
    const p = -(o?.rotate?.[1]);
    const k = +(o?.scale);
    return (l === l && p === p && k === k) ? `${l.toFixed(2)},${p.toFixed(2)},${Math.round(k)}` : undefined;
}

// const DEFAULT_CONFIG = "current/wind/surface/level/orthographic";
function defaultState() {
    return ø({
        mode: "air",                      // non-empty alphanumeric _
        projection_type: "orthographic",  // "orthographic", "atlantis", "equirectangular", ...
        orientation: ø({                  // orientation can be undefined
            rotate: undefined,            // number array [λ, φ] or undefined
            scale: undefined              // number or undefined
        }),

        time_cursor: "current",           // "current" or {year:, month:, day:, hour:, minute:}
        z_level: "surface",               // "surface", "1000hPa", ..., "10hPa"   [ non-empty alphanumeric _ ]
        animation_type: "wind",           // "wind", "currents", "primary_waves"  [ non-empty alphanumeric _ ]
        overlay_type: "wind",

        spotlight_coords: undefined,      // [λ, φ] coordinates of spotlight marker or undefined
        show_grid_points: false,
        animation_enabled: true,

        argoFloat: undefined,
    });
}

function initialState() {
    const empty = ø();
    Object.keys(defaultState()).forEach(key => empty[key] = undefined);
    return empty;
}

function fromHashFragment(hash) {
    let option;
    //                1        2        3          4          5            6      7      8    9
    const tokens = /^#(current|(\d{4})\/(\d{1,2})\/(\d{1,2})\/(\d{3,4})Z)\/(\w+)\/(\w+)\/(\w+)([\/].+)?/.exec(hash);
    if (!tokens) {
        return defaultState();
    }
    const time_cursor = tokens[1] === "current" ? "current" : {year: +tokens[2], month: +tokens[3], day: +tokens[4]};
    if (time_cursor !== "current") {
        const hour = (tokens[5].length === 3 ? "0" : "") + tokens[5];
        time_cursor.hour = +hour.substr(0, 2);
        time_cursor.minute = +hour.substr(2);
    }

    const result = defaultState();
    result.time_cursor = time_cursor;
    Object.assign(result, fragmentToState(tokens[6], tokens[8]));
    result.overlay_type = result.animation_type;  // by default, use overlay that matches the animation

    (tokens[9] ?? "").split("/").forEach(function(segment) {
        if ((option = /^(\w+)(=([\d\-.,]*))?$/.exec(segment))) {
            if (_.has(globes, option[1])) {
                result.projection_type = option[1];
                result.orientation = fragmentToOrientation(option[3]);
            } else if (option[1] === "loc") {
                const parts = typeof option[3] === "string" ? option[3].split(",") : [];
                const λ = +parts[0], φ = +parts[1];
                if (λ === λ && φ === φ) {
                    result.spotlight_coords = [λ, φ];
                }
            }
        } else if ((option = /^overlay=([\w.]+)$/.exec(segment))) {
            const overlay_type = option[1] === "off" ? "none" : option[1];
            if (overlayTypes.has(overlay_type)) {
                result.overlay_type = overlay_type;
            }
        } else if ((option = /^grid=(\w+)$/.exec(segment))) {
            if (option[1] === "on") {
                result.show_grid_points = true;
            }
        } else if ((option = /^anim=(\w+)$/.exec(segment))) {
            if (option[1] === "off") {
                result.animation_enabled = false;
            }
        } else if ((option = /^argo=(\w+)$/.exec(segment))) {
            switch (option[1]) {
                case "planned":
                case "recent":
                case "operational":
                case "dead":
                    result.argoFloat = option[1];
            }
        }
    });
    return result;
}

function toHashFragment(attr) {
    const {
        mode,
        projection_type,
        orientation,
        time_cursor,
        z_level,
        animation_type,
        overlay_type,
        spotlight_coords,
        show_grid_points,
        animation_enabled,
        argoFloat,
    } = attr;

    const dir = time_cursor === "current" ? "current" : utc.print(time_cursor, "{YYYY}/{MM}/{DD}/{hh}{mm}Z");
    const [param, surface, level] = stateToFragment(mode, animation_type, z_level);
    const anim = animation_enabled ? "" : "anim=off";
    const ot = !overlay_type || overlay_type === animation_type ? "" : `overlay=${overlay_type}`;
    const grid = show_grid_points ? "grid=on" : "";
    const argo = argoFloat ? "argo=" + argoFloat : "";
    const proj = [projection_type, orientationToFragment(orientation)].filter(x => x).join("=");
    const loc = spotlight_coords ? "loc=" + spotlight_coords.map(e => e.toFixed(3)).join(",") : "";
    return "#" + [dir, param, surface, level, anim, ot, grid, argo, proj, loc].filter(x => x).join("/");
}

export const model = createModel(initialState());

function readHashFragment() {
    const hash = window.location.hash;
    const attributes = fromHashFragment(hash);
    model.save(attributes, {source: "hash"});
}

function writeHashFragment(delta, old, meta = {}) {
    const attributes = model.getAll();
    const changed = toHashFragment(attributes);
    // change the hash fragment only when there's a difference, and only when the change wasn't itself triggered by
    // a hash change.
    if (window.location.hash !== changed && meta.source !== "hash") {
        window.location.hash = changed;
    }
}

export function attach() {
    model.on(seq`change.?`, writeHashFragment);
    d3.select(window).on(seq`hashchange.?`, readHashFragment);
    readHashFragment();
}
