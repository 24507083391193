
import {parseQueryString} from "./strings.js";

const UA = navigator.userAgent ?? "";

export function isDevContext() {
    return +window.location.port === 8082;
}

export function isIFrameContext() {
    return window.self !== window.top;
}

export function isAppContext() {
    return parseQueryString(window.location.search).has("app");
}

export function isKioskContext() {
    const host = document.referrer.split("/")[2] || "";
    const override = /dailymail/i.test(host);
    return parseQueryString(window.location.search).has("kiosk") && !override;
}

export function isDebugContext() {
    return parseQueryString(window.location.search).has("debug");
}

export function siteInstance() {
    // UNDONE: siteInstance may no longer be needed because Cloudflare responds with "vary: Origin" CORS header set.
    const match = window.location.hostname.match(/(.*)\.nullschool\.net$/) || [], name = match[1] || "earth";
    return name === "earth" ? "" : name;
}

export function isIE11() {
    return !!window.msCrypto;
}

/**
 * @returns {boolean} true if agent is probably firefox. Don't really care if this is accurate.
 */
export function isFF() {
    return (/firefox/i).test(UA);
}

export function isSafari() {
    return (/safari/i).test(UA);
}

/**
 * @returns {boolean} true if agent is probably iOS. Don't really care if this is accurate.
 */
export function isIOS() {
    // https://stackoverflow.com/questions/56578799/tell-ipados-from-macos-on-the-web
    return (/ipad|iphone|ipod/i).test(UA) || (/macintosh/i).test(UA) && navigator.maxTouchPoints > 2;
}

export function isAndroid() {
    return /android/i.test(UA);
}

/**
 * @returns {boolean} true if agent is probably a mobile device. Don't really care if this is accurate.
 */
export function isMobile() {
    return (/android|blackberry|iemobile|ipad|iphone|ipod|opera mini|webos/i).test(UA);
}

export function isWebView() {
    // https://developer.chrome.com/multidevice/user-agent
    return isAndroid() && /\swv/.test(UA) || isIOS() && !isSafari();
}

// UNDONE: test for fullscreen / app mode:
//         https://www.quora.com/How-do-I-know-if-a-user-is-accessing-a-PWA-app-from-a-browser-or-a-home-screen

export function isHeadless() {
    return /headless/i.test(UA);
}
