
import {loadJson} from "../network/fetcher.js";
import {taraUrl} from "../product/gaia.js";
import {feature} from "topojson-client";
import {isMobile, siteInstance} from "../util/context.js";

const mobile = isMobile();

/**
 * @param resource the GeoJSON resource's URL
 * @param report
 * @param cancel
 * @returns {Promise} a promise for GeoJSON topology features: {boundaryLo:, boundaryHi:}
 */
export function buildMesh(resource, report, cancel) {
    report.status("Downloading...");
    const files = [];
    files.push(loadJson(resource));
    if (siteInstance() === "tara") {
        files.push(loadJson(taraUrl("tara-plan-20170829.json")));
        files.push(loadJson(taraUrl("tara-track.json")));
    }

    return Promise.all(files).then(([topo, taraPlan, taraTrack]) => {
        if (cancel.requested) return null;
        console.time("building meshes");
        const o = topo.objects;
        const coastLo = feature(topo, mobile ? o.coastline_tiny : o.coastline_110m);
        const coastHi = feature(topo, mobile ? o.coastline_110m : o.coastline_50m);
        const lakesLo = feature(topo, mobile ? o.lakes_tiny : o.lakes_110m);
        const lakesHi = feature(topo, mobile ? o.lakes_110m : o.lakes_50m);
        const riversLo = feature(topo, mobile ? o.rivers_tiny : o.rivers_110m);
        const riversHi = feature(topo, mobile ? o.rivers_110m : o.rivers_50m);

        console.timeEnd("building meshes");
        return {
            coastLo: coastLo,
            coastHi: coastHi,
            lakesLo: lakesLo,
            lakesHi: lakesHi,
            riversLo: riversLo,
            riversHi: riversHi,
            tara: {plan: taraPlan, track: taraTrack},
        };
    });
}
