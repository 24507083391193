
import * as palette from "./palette.js";

export default function(resolution = 2000) {

    const bounds = [40, 2500];  // units: ppb
    const stops = [
        {color: [  0,  38,  40], mode: "lab", p: bounds[0]},
        {color: [255, 255, 224], mode: "hcl", p: 400},  // background is ~100 ppb https://en.wikipedia.org/wiki/MOPITT
        {color: [  0,   0, 154], mode: "lab", p: 1800},
        {color: [  0,   0,   0], mode: "lab", p: bounds[1]}
    ];

    let interpolators = palette.interpolatorsFrom(stops);
    interpolators = palette.smooth(interpolators, 0, [340, 460]);
    interpolators = palette.smooth(interpolators, 2, [1500, 2100]);
    return palette.buildScaleFromInterpolators(bounds, interpolators, resolution);

}
