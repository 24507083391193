
import {isKioskContext} from "../util/context.js";

const co2Link = isKioskContext() ? "" : " [<a href='about.html#co2' class='internal-link'>important note</a>]";

export const argo = "Argo";
export const cams = "CAMS / Copernicus / EC + ECMWF";
export const geos = "GEOS-5 / GMAO / NASA";
export const geosAdjustedCO2 = "GEOS-5 / GMAO / NASA" + co2Link;
export const gfs = "GFS / NCEP / US National Weather Service";
export const hrrr = "HRRR / NCEP / US National Weather Service";
export const oscar = "OSCAR / Earth & Space Research";
export const ostia = "OSTIA / UK Met Office + GHRSST + CMEMS";
export const ovation = "OVATION / SWPC / NCEP / NWS / NOAA";
export const rtgsst = "RTG-SST / NCEP / US National Weather Service";
export const somffn = "SOM-FFN / Landschützer et al. / MPI-M";
export const ww3 = "WAVEWATCH III / NCEP / NWS";
