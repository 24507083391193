
import {ø, keep} from "../../util/objects.js";
import * as utc from "../../util/utc.js";
import * as clock from "../../util/clock.js";
import * as gaia from "../gaia.js";
import {scalarProduct} from "../scalarProduct.js";
import * as sources from "../sources.js";


function recipeFor(attr, name) {
    const SST = "sea_surface_temp";
    const SSTA = "sea_surface_temp_anomaly";
    if (name === SST) {
        return attr.hd_enabled ?
            {tag: SST, res: "0.1", selector: /analysed_sst/} :
            {tag: SST, res: "0.5", selector: /analysed_sst/};
    }
    if (name === SSTA) {
        return attr.hd_enabled ?
            {tag: SSTA, res: "0.25", selector: /sst_anomaly/} :
            {tag: SSTA, res: "0.5",  selector: /sst_anomaly/};
    }
}

export function ostiaValidTime(time_cursor) {
    let parts;
    if (time_cursor === "current") {
        // Each day, SST data for the previous day is made available. So "current" means yesterday.
        const now = keep(utc.parts(clock.now()), "year", "month", "day");
        parts = utc.add(now, {day: -1});
    } else {
        parts = ø(time_cursor);
    }
    return parts;
}

/**
 * Returns a date for the chronologically next or previous OSTIA SST data layer. How far forward or backward in time
 * to jump is determined by the step. Steps of ±1 move in 1 day jumps, and steps of ±10 move in 5-day jumps.
 */
export function ostiaStep(validTime, step) {
    const offset = step > 1 ? 5 : step < -1 ? -5 : step;
    return utc.add(validTime, {day: offset});
}

export function ostiaPath(attr, name) {
    // 2019/12/20191215-sst-ostia-0.1.epak
    // 2019/12/20191215-ssta-ostia-0.25.epak
    // current/current-sst-ostia-0.1.epak

    const recipe = recipeFor(attr, name);
    const isCurrent = attr.time_cursor === "current";
    const dir = isCurrent ? "current" : utc.print(attr.time_cursor, "{YYYY}/{MM}");
    const timestamp = isCurrent ? "current" : utc.print(attr.time_cursor, "{YYYY}{MM}{DD}");
    const file = `${timestamp}-${recipe.tag}-ostia-${recipe.res}.epak`;
    return gaia.ostiaUrl(`${dir}/${file}`);
}

export function ostiaProduct(attr, file, name) {
    const recipe = recipeFor(attr, name);
    return Object.assign(scalarProduct(file, recipe.selector, {hasMissing: true}), {sourceHTML: sources.ostia});
}
