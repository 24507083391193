
import * as palette from "./palette.js";

export default function(resolution = 2000) {
    const bounds = [0, 150];  // units: kg/m^3 == mm

    const stops = [
        {color: [ 37,  79,  92], mode: "hcl", p: bounds[0]},
        {color: "aliceblue",     mode: "hcl", p: 2},
        {color: [ 51,  26, 155], mode: "hcl", p: 15},
        {color: "gold",          mode: "hcl", p: 100},
        {color: "white",         mode: "hcl", p: bounds[1]}
    ];

    let interpolators = palette.interpolatorsFrom(stops);
    interpolators = palette.smooth(interpolators, 0, [0.6, 3.4]);
    interpolators = palette.smooth(interpolators, 2, [12, 18]);
    return palette.buildScaleFromInterpolators(bounds, interpolators, resolution);

}

/*
https://en.wikipedia.org/wiki/Rain#Intensity

Light rain - when the precipitation rate is < 2.5 mm (0.098 in) per hour
Moderate rain - when the precipitation rate is between 2.5 mm (0.098 in) - 7.6 mm (0.30 in) or 10 mm (0.39 in) per hour
Heavy rain - when the precipitation rate is > 7.6 mm (0.30 in) per hour, or between 10 mm (0.39 in) and 50 mm (2.0 in) per hour
Violent rain - when the precipitation rate is > 50 mm (2.0 in) per hour
*/
