
import * as utc from "../util/utc.js";
import {tr} from "../ux/translations.js";
import {capitalize} from "../util/strings.js";
import {createUnitDescriptors} from "../util/units.js";
import * as clock from "../util/clock.js";

export function describeSurface(attr) {
    return attr.z_level === "surface" ? tr("Surface") : capitalize(attr.z_level);
}

// UNDONE: better more generic name? not really specific to "validTime" calculation
export function resolveValidTime(time_cursor, alignment, offset) {
    const alignmentMillis = alignment.hour * 60 * 60 * 1000;
    const timestamp = (time_cursor ?? "current") === "current" ? clock.now() : utc.date(time_cursor);
    const aligned = Math.floor(timestamp / alignmentMillis) * alignmentMillis;
    return utc.normalize(utc.add(utc.parts(aligned), offset ?? {}));
}

export function newProduct() {
    return {
        descriptionHTML: undefined,

        paths: [],

        /** @returns {Object} the product's date parts. */
        validTime: function() {
            return null;
        },

        /** @returns {string} the product's appropriate date format string. */
        dateFormat: function() {
            return "{YYYY}-{MM}-{DD} {hh}:{mm}";
        },

        /**
         * Returns date parts for the chronologically next or previous data layer assuming a default cadence of 3 hours.
         * How far forward or backward in time to jump is determined by the step. Steps of ±1 move in 3-hour jumps,
         * and steps of ±10 move in 24-hour jumps.
         *
         * @param {number} step the number of steps.
         * @returns {Object} the date parts for the specified number of steps away from this product's date.
         */
        navigate: function(step) {
            const offset = (step > 1 ? 8 : step < -1 ? -8 : step) * 3;
            return utc.add(this.validTime(), {hour: offset});
        },

        // UNDONE: only here for compatibility
        prev2: function() { return this.navigate(-10); },
        prev1: function() { return this.navigate( -1); },
        next1: function() { return this.navigate( +1); },
        next2: function() { return this.navigate(+10); },

        navLabels: {
            prev2: tr("-10 NYI"),
            prev1: tr("-1 NYI"),
            next1: tr("+1 NYI"),
            next2: tr("+10 NYI"),
        },

        alpha: {single: 160, animated: 112},

        // /**
        //  * @param {number} λ
        //  * @param {number} φ
        //  * @returns {number|number[]} the value at the specified coords, or NaN or [NaN, NaN]
        //  */
        // valueAt(λ, φ) {
        //     throw new Error("must override");
        // },
        //
        // /**
        //  * @param {number} t
        //  * @returns {number|number[]} a value representative of the percentage through the layer's valid range
        //  */
        // valueInRange(t) {
        //     throw new Error("must override");
        // },
    };
}

export function buildProduct(overrides) {
    overrides.unitDescriptors = createUnitDescriptors(overrides.unitDescriptors);
    return Object.assign(newProduct(), overrides);
}
