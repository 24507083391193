
import * as palette from "./palette.js";

export default function(resolution = 1000) {

    const bounds = [270, 304.65];  // units: Kelvin
    const segments = [
        [270,    [255, 255, 255]],
        [271.25, [255, 255, 255]],  // -1.9 C sea water freeze
        [271.30, [ 15,   4, 168]],
        [273.15, [ 15,  54, 208]],  // 0 C fresh water freeze
        [273.25, [ 15,  54, 188]],
        [275.65, [ 15,   4, 168]],  // lower boundary for cool currents
        [281.65, [ 24, 132,  14]],  // upper boundary for cool currents
        [291.15, [247, 251,  59]],  // lower boundary for warm currents
        [295,    [235, 167,   0]],
        [299.65, [245,   0,  39]],  // minimum needed for tropical cyclone formation
        [303,    [ 87,  17,   0]],
        [304.65, [238,   0, 242]],
    ];
    return palette.buildScaleFromSegments(bounds, segments, resolution);

}
