
import {isEqual, isFunction, isString} from "../lib/underscore.js";

/**
 * @param {...Object} [sources] the source objects.
 * @returns {Object} returns a new object, with no prototype, containing properties from the sources.
 */
export function ø(sources) {
    return Object.assign(Object.create(null), ...arguments);
}

export function omit(obj, ...discard) {
    const result = ø();
    Object.keys(obj).forEach(key => {
        if (discard.indexOf(key) < 0) {
            result[key] = obj[key];
        }
    });
    return result;
}

export function keep(obj, ...retain) {
    const result = ø();
    Object.keys(obj).forEach(key => {
        if (retain.indexOf(key) >= 0) {
            result[key] = obj[key];
        }
    });
    return result;
}

/**
 * @param {object} a potential superset
 * @param {object} b potential subset
 * @returns {boolean} true if every k,v pair in b is also in a, using deep equals comparison.
 */
export function isSubset(a, b) {
    return Object.keys(b).every(key => isEqual(a[key], b[key]));
}

/**
 * @param obj
 * @returns {boolean} true if the object looks like a Promise
 */
export function isPromise(obj) {
    return isFunction(obj?.then);
}

/**
 * Truncate any keys and (string) values to a maximum length.
 *
 * @param {{}} obj
 * @param {number} maxKeyLength
 * @param {number} maxValueLength
 * @returns {{}} a copy of the object with truncated entries
 */
export function truncateEntries(obj, maxKeyLength, maxValueLength) {
    const result = {};
    for (const [k, v] of Object.entries(obj)) {
        // UNDONE: nested objects
        const key = k.substring(0, maxKeyLength);
        const value = isString(v) ? v.substring(0, maxValueLength) : v;
        if (key in result) {
            throw new Error(`key collision: ${key}`);
        }
        result[key] = value;
    }
    return result;
}
