
import * as palette from "./palette.js";

export default function(resolution = 1000) {

    const bounds = [-10, 10];  // units: mol/m^2*yr
    const stops = [
        {color: [216, 218, 235], mode: "lab", p:   -10},
        {color: [178, 171, 210], mode: "lab", p:    -8},
        {color: [128, 115, 172], mode: "lab", p:    -6},
        {color: [ 84,  39, 136], mode: "lab", p:  -3.5},
        {color: [ 45,   0,  75], mode: "lab", p: -0.75},
        {color: [ 18,  18,  18], mode: "lab", p:     0},
        {color: [127,  59,   8], mode: "lab", p:     2},
        {color: [179,  88,   6], mode: "lab", p:     4},
        {color: [224, 130,  20], mode: "lab", p:     6},
        {color: [253, 184,  99], mode: "lab", p:     8},
        {color: [254, 224, 182], mode: "lab", p:    10},
    ];

    const interpolators = palette.interpolatorsFrom(stops);
    return palette.buildScaleFromInterpolators(bounds, interpolators, resolution);

}
