
import * as palette from "./palette.js";

export default function(resolution = 400) {

    //      v*1e-14
    //             1
    //         3,000  boat trail start
    //        20,000
    //       115,000  boat trail peak
    //       262,000     1 ppb
    //    19,650,000    75 ppb, EPA 1hr std limit
    //    65,500,000   250 ppb, Amer Conf of Gvt Indus Hygen short term exposure limit
    //   163,226,000   623 ppb, largest seen in 2 weeks of geos-driver
    //   484,962,000  1851 ppb, top of log scale
    // 1,310,000,000  5000 ppb, OSHA PEL

    const bounds = [10e-14, 88800000e-14];  // units: kg/m^3
    const logBounds = bounds.map(Math.log);
    const stops = [
        {color: [134, 134, 107], mode: "hcl", p: logBounds[0]},
        {color: [144, 144, 117], mode: "hcl", p: Math.log(100e-14)},
        {color: [255, 255, 224], mode: "hcl", p: Math.log(7000e-14)},
        {color: [  0,   0, 128], mode: "lab", p: Math.log(19000000e-14)},
        {color: [ 23,  20,  18], mode: "lab", p: logBounds[1]}
    ];

    let interpolators = palette.interpolatorsFrom(stops);
    interpolators = palette.smooth(interpolators, 1, [Math.log(5000e-14), Math.log(9000e-14)]);
    interpolators = palette.smooth(interpolators, 3, [Math.log(8000000e-14), Math.log(40000000e-14)]);
    const colors = palette.quantize(logBounds, interpolators, resolution);

    return palette.buildScale(bounds, colors, Math.log, Math.exp);

}
