
import * as d3 from "../lib/d3.js";

/**
 * D3 selector for elements that can possibly receive browser focus. Note that some elements may not actually be
 * focusable due to hidden parents, invisibility, inertness, etc.
 */
export const focusableElements = d3.selectorAll("a[href], button, [tabindex]");

/**
 * @param elements a d3 selection of elements.
 * @returns a d3 selection of all unique parent elements.
 */
export function parentsOf(elements) {
    const parents = elements.select(function() { return this.parentElement; }).nodes();
    return d3.selectAll([...new Set(parents)]);
}

/**
 * @param elements a d3 selection of elements.
 * @returns a d3 selection of all child elements.
 */
export function childrenOf(elements) {
    return elements.selectAll(function() { return this.children; });
}

/**
 * @param elements a d3 selection of elements.
 * @returns a d3 selection of all siblings (not including the provided elements).
 */
export function siblingsOf(elements) {
    const self = new Set(elements.nodes());
    return childrenOf(parentsOf(elements)).filter(function() { return !self.has(this); });
}
