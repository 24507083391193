
import {model as sharedConfig} from "../framework/sharedState.js";
import {floorMod, rescale} from "../util/math.js";
import * as d3 from "../lib/d3.js";
import * as twod from "../canvas/twod.js";

const MAX_TASK_TIME = 40;   // amount of time before a task yields control (millis)
const MIN_SLEEP_TIME = 10;  // amount of time a task waits before resuming (millis)

// CONSIDER: Interleave grid points to improve UX of very dense grids, to avoid them filling slowly from top-to-bottom
function drawGridPoints(ctx, layer, globe, viewboxAgent, cancel) {
    if (!layer || !globe || !sharedConfig.get("show_grid_points")) return;

    ctx.fillStyle = "rgba(255, 255, 255, 0.75)";
    const {width, height} = viewboxAgent.value();
    const stream = globe.projection.stream({
        point(x, y) {
            if (0 <= x && x < width && 0 <= y && y < height) {
                // fillRect is processed at the end of the event turn, but it alpha blends, as opposed to setting
                // the pixel directly.
                ctx.fillRect(Math.round(x), Math.round(y), 1, 1);
            }
        },
    });
    const grid = layer.grid();
    const {isDefined} = layer.field();
    let i = 0;

    // Draw grid points in batches.
    (function work() {
        if (!cancel.requested) {
            const end = Date.now() + MAX_TASK_TIME;
            i = grid.forEach((λ, φ, i) => {
                if (isDefined(i)) {
                    λ = floorMod(180 + λ, 360) - 180;  // some projections don't map to [-180, 180)
                    stream.point(λ, φ);
                }
                return Date.now() > end;
            }, i);
            if (i === i) {
                setTimeout(work, MIN_SLEEP_TIME);
            }
        }
    })();
}

function brighten(data, alpha) {
    for (let i = 3; i < data.length; i += 4) {
        if (data[i] !== 0) {
            data[i] = alpha;
        }
    }
}

export function drawOverlay(
    overlayGridAgent,
    rendererAgent,
    activeLayerAgent,
    globeAgent,
    viewboxAgent,
    field,
    overlay_type,
    animation_enabled,
    createUnitToggle
) {
    if (!field || !rendererAgent.value()) return;

    const ctx = d3.select("#overlay").node().getContext("2d");
    const layer = activeLayerAgent.value();

    twod.clearContext(ctx);
    twod.clearCanvas(d3.select("#scale").node());
    if (overlay_type) {
        if (overlay_type !== "none") {
            if (!animation_enabled) {
                // No animation, so brighten the overlay.
                brighten(field.overlay.data, layer.alpha.single);
            }
            ctx.putImageData(field.overlay, 0, 0);
        }
        overlayGridAgent.submit(function() {
            return drawGridPoints(ctx, layer, globeAgent.value(), viewboxAgent, this.cancel);
        });
    }

    if (layer) {
        const colorBar = d3.select("#scale"), c = colorBar.node(), g = c.getContext("2d"), barMax = c.width - 1;
        const colorScale = layer.scale, colors = colorScale.colors, colorMax = colors.length / 4 - 1;
        for (let i = 0; i < c.width; i++) {
            const j = Math.round(i / barMax * colorMax) * 4;
            g.fillStyle = `rgb(${colors[j]},${colors[j+1]},${colors[j+2]})`;
            g.fillRect(i, 0, 1, c.height);
        }

        // Show tooltip on hover.
        colorBar.on("mousemove.color-bar-tooltip", function() {
            const x = Math.floor(d3.mouse(this)[0]);
            if (x < 0 || barMax < x) {
                return colorBar.attr("title", null);
            }
            const t = rescale(x, 0, barMax, 0, 1);
            const value = layer.valueInRange(t);
            const elementId = layer.type === "wind" ? "#location-wind-units" : "#location-value-units";
            const units = createUnitToggle(elementId, layer).value();
            colorBar.attr("title", units.format(value).scalarHTML ?? "");
        });
    }
}
