
import * as palette from "./palette.js";

export default function(resolution = 1000) {

    const bounds = [0, 1.5];  // units: m/s
    const segments = [
        [0,    [ 10,  25,  68]],
        [0.15, [ 10,  25, 250]],
        [0.4,  [ 24, 255,  93]],
        [0.65, [255, 233, 102]],
        [1.0,  [255, 233,  15]],
        [1.5,  [255,  15,  15]],
    ];
    return palette.buildScaleFromSegments(bounds, segments, resolution);

}
