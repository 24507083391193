/**
 * micro - a grab bag of somewhat useful utility functions and other stuff that requires unit testing
 */

/**
 * Removes all children of the specified DOM element.
 */
export function removeChildren(element) {
    while (element.firstChild) {
        element.removeChild(element.firstChild);
    }
}

/**
 * @returns {*} a new mouse click event instance
 */
export function newClickEvent() {
    try {
        return new MouseEvent("click", {view: window, bubbles: true, cancelable: true});
    }
    catch (e) {
        // Chrome mobile supports only the old fashioned, deprecated way of constructing events. :(
        const event = document.createEvent("MouseEvents");
        event.initMouseEvent("click", true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
        return event;
    }
}
