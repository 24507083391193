
import {createModel} from "./model.js";
import {isMobile} from "../util/context.js";

function topologyFile() {
    return isMobile() ? "/earth-topo-mobile.json?v3" : "/earth-topo.json?v3";
}

export const model = createModel({
    hd_enabled: undefined,
    interpolation_type: undefined,
    topology: undefined,
});

export function attach() {
    model.save({hd_enabled: false, interpolation_type: "bilinear", topology: topologyFile()});
}
