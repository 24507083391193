
import * as palette from "./palette.js";

export default function(resolution = 500) {

    const bounds = [0.002, 2.500];  // units: τ
    const logBounds = bounds.map(Math.log);
    const interpolator = palette.interpolateCubehelix(logBounds);
    const colors = palette.quantize(logBounds, [interpolator], resolution);

    return palette.buildScale(bounds, colors, Math.log, Math.exp);

}
