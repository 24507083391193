
import * as palette from "./palette.js";

export default function(resolution = 1000) {

    const bounds = [1e-9, 1000e-9];  // units: µg/m^3
    const logBounds = bounds.map(Math.log);

    // combination of colorbrewer YlGnBu and YlOrRd
    const stops = [
        {color: [  8,  29,  88], mode: "hcl", p: logBounds[0]},
        {color: [ 37,  52, 148], mode: "hcl", p: Math.log(4e-9)},
        {color: [ 34,  94, 168], mode: "hcl", p: Math.log(6e-9)},
        {color: [ 29, 145, 192], mode: "hcl", p: Math.log(8e-9)},
        {color: [ 65, 182, 196], mode: "hcl", p: Math.log(10e-9)},
        {color: [127, 205, 187], mode: "hcl", p: Math.log(16e-9)},
        {color: [199, 233, 180], mode: "hcl", p: Math.log(24e-9)},
        {color: [237, 248, 177], mode: "hcl", p: Math.log(28e-9)},
        {color: [255, 255, 210], mode: "hcl", p: Math.log(32e-9)},
        {color: [255, 237, 160], mode: "hcl", p: Math.log(42e-9)},
        {color: [254, 217, 118], mode: "hcl", p: Math.log(47e-9)},
        {color: [254, 178,  76], mode: "hcl", p: Math.log(57e-9)},
        {color: [253, 141,  60], mode: "hcl", p: Math.log(67e-9)},
        {color: [252,  78,  42], mode: "hcl", p: Math.log(110e-9)},
        {color: [227,  26,  28], mode: "hcl", p: Math.log(250e-9)},
        {color: [189,   0,  38], mode: "hcl", p: Math.log(500e-9)},
        {color: [128,   0,  38], mode: "hcl", p: logBounds[1]}
    ];

    let interpolators = palette.interpolatorsFrom(stops);
    interpolators = palette.smooth(interpolators, 7, [Math.log(30e-9), Math.log(35e-9)]);
    const colors = palette.quantize(logBounds, interpolators, resolution);
    return palette.buildScale(bounds, colors, Math.log, Math.exp);

}
