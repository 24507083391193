
import * as palette from "./palette.js";

export default function(resolution = 1000) {

    const bounds = [236, 332];  // units: kelvin
    const segments = [
        [241,   [255, 255, 255]],  // -32 C, -25 F extreme frostbite
        [245.5, [  6,  82, 255]],
        [250,   [  6,  82, 255]],  // -23 C, -10 F frostbite
        [258,   [ 46, 131, 255]],
        [266,   [ 46, 131, 255]],  // -7 C, 20 F hypothermia
        [280,   [  0,   0,   0]],  // 7 C, 45 F begin suckage (cold)
        [300,   [  0,   0,   0]],  // 27 C, 80 F begin caution (heat)
        [305,   [247,  20,  35]],  // 32 C, 90 F extreme caution
        [309.5, [247,  20,  35]],
        [314,   [245, 210,   5]],  // 41 C, 105 F danger
        [320.5, [245, 210,   5]],
        [327,   [255, 255, 255]],  // 54 C, 130 F extreme danger
    ];
    return palette.buildScaleFromSegments(bounds, segments, resolution);

}
