
import * as palette from "./palette.js";

export default function(resolution = 1000) {

    const bounds = [0, 70];  // units: kg/m^2
    const segments = [
        [ 0, [230, 165,  30]],
        [10, [120, 100,  95]],
        [20, [ 40,  44,  92]],
        [30, [ 21,  13, 193]],
        [40, [ 75,  63, 235]],
        [60, [ 25, 255, 255]],
        [70, [150, 255, 255]],
    ];
    return palette.buildScaleFromSegments(bounds, segments, resolution);

}
