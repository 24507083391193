
import * as palette from "./palette.js";

export default function(resolution = 1000) {

    const bounds = [0, 25];  // units: seconds (period)
    const segments = [
        [ 0, [  0,   0,   0]],
        [25, [ 21, 255, 255]],
    ];
    return palette.buildScaleFromSegments(bounds, segments, resolution);

}
