
import * as palette from "./palette.js";

export default function(resolution = 1000) {

    const bounds = [0, 100];
    const stops = [
        {color: [  0,   0,   0], mode: "lab", p: bounds[0]},
        {color: [  4,   8,  34], mode: "lab", p: 5},
        {color: [ 78, 255, 103], mode: "lab", p: 40},
        {color: [252, 255,  57], mode: "lab", p: 60},
        {color: [219,  75, 255], mode: "lab", p: bounds[1]}
    ];
    let interpolators = palette.interpolatorsFrom(stops);
    interpolators = palette.smooth(interpolators, 1, [35, 45]);
    interpolators = palette.smooth(interpolators, 3, [55, 65]);
    return palette.buildScaleFromInterpolators(bounds, interpolators, resolution);
}
