
import * as d3 from "../lib/d3.js";

const denylist = [
    /dailymail/i,
    /tamilinstanews/i,
    /xtremeweatherforecast/i,
    /oracle77.asuscomm/i
];

export function contains(referrer) {
    const host = referrer.split("/")[2] ?? "";
    return denylist.some(regex => regex.test(host));
}

export function deny() {
    return d3.select("body")
        .attr("style", "font-size: 12em; color: red;")
        .text("XX - contact @cambecc")
      .selectAll("*")
        .remove();
}
