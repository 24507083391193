
import * as palette from "./palette.js";

export default function(resolution = 30) {

    const bounds = [200, 560];  // units: µatm
    const interpolator = palette.interpolateCubehelix(bounds);
    const colors = palette.quantize(bounds, [interpolator], resolution);
    return palette.buildScale(bounds, colors);

}
