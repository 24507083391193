
import * as utc from "../../util/utc.js";
import * as gaia from "../gaia.js";
import * as clock from "../../util/clock.js";
import {buildProduct} from "../productUtils.js";
import {tr} from "../../ux/translations.js";
import * as sources from "../sources.js";
import flatProduct from "../flatProduct.js";
import * as palettes from "../../palette/palettes.js";

function ovationValidTime(time_cursor) {
    const {year, month, day, hour, minute} = time_cursor === "current" ? utc.parts(clock.now()) : time_cursor;
    return {year, month, day, hour, minute: Math.floor(minute / 5) * 5};
}

function ovationStep(validTime, step) {
    const offset = (step > 1 ? 12 : step < -1 ? -12 : step);
    return utc.normalize(utc.add(validTime, {minute: offset * 5}));
}

function ovationPath(attr) {
    const validTime = ovationValidTime(attr.time_cursor);
    const isCurrent = attr.time_cursor === "current";
    const dir = isCurrent ? "current" : utc.print(validTime, "{YYYY}/{MM}/{DD}");
    const stamp = isCurrent ? "current" : utc.print(validTime, "{YYYY}{MM}{DD}{hh}{mm}");
    return gaia.ovationUrl(`${dir}/${stamp}-ovation.json`);
}

export function createAuroraLayer(attr) {

    // HACK: Because some time slots will be filled by older data (since the data source is not reliable),
    // we want to use the date in the URL as the basis of navigation rather than the date from the
    // downloaded header. The validTime() method is replaced by flatProduct to use the header date, so this
    // function can be used to avoid that.
    function validTimeFromAttributes() {
        return ovationValidTime(attr.time_cursor);
    }

    return buildProduct({
        type: "aurora",
        descriptionHTML: {
            name: tr("Probability of Visible Aurora"),
            qualifier: "",
        },
        sourceHTML: sources.ovation,
        paths: [ovationPath(attr)],
        validTime: validTimeFromAttributes,
        navigate: function(step) {
            return ovationStep(validTimeFromAttributes(), step);
        },
        builder: function(file) {
            file.has_missing ??= false;
            return flatProduct(file);
        },
        unitDescriptors: {
            "%": {html: tr("% Visibility Chance"), convert: x => x, precision: 0},
        },
        alpha: {single: 160, animated: 140},
        scale: palettes.aurora(),
    });
}
