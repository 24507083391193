
import * as _ from "../lib/underscore.js";
import * as utc from "../util/utc.js";
import * as d3 from "../lib/d3.js";
import {model as sharedConfig} from "../framework/sharedState.js";
import {removeChildren} from "../util/micro.js";
import {makeStrokeRenderer, makeLayerRenderer} from "../canvas/twod.js";
import {siteInstance} from "../util/context.js";

export function buildRenderer(
    rendererAgent,
    inputController,
    mesh,
    globe,
    report,
    updateSpotlight,
    orient,
    viewboxAgent,
) {
    if (!mesh || !globe) return null;

    report.status("Rendering Globe...");
    console.time("rendering map");

    const mapCtx = d3.select("#map").node().getContext("2d");
    const foregroundCtx = d3.select("#foreground").node().getContext("2d");
    const annotationSvg = d3.select("#annotation");
    const orientation = sharedConfig.get("orientation");

    // First clear annotation svg contents.
    removeChildren(annotationSvg.node());
    globe.setOrientation(orientation, viewboxAgent.value());  // This allows the path function to work.

    const path = d3.geoPath().projection(globe.projection).pointRadius(function(d) { return d.size || 7; });

    if (siteInstance() === "tara") {
        constructTaraElements(annotationSvg, mesh.tara, orientation, orient);
    }

    // Draw the location mark if one is currently visible.
    updateSpotlight();

    // Throttled draw method helps with slow devices that would get overwhelmed by too many redraw events.
    const REDRAW_WAIT = 5;  // milliseconds
    let doDraw_throttled = _.throttle(doDraw, REDRAW_WAIT, {leading: false});

    const LINE_WIDTH = 1.25;
    // CONSIDER: make these lines brighter/more opaque when viewing ocean layers
    const coastStyle = {strokeStyle: "rgba(250,250,250,0.65)", lineWidth: LINE_WIDTH};
    const lakesStyle = {strokeStyle: "rgba(250,250,250,0.65)", lineWidth: LINE_WIDTH};
    const riverStyle = {strokeStyle: "rgba(220,220,220,0.3)", lineWidth: LINE_WIDTH};

    const background = globe.backgroundRenderer();
    const graticule = globe.graticuleRenderer();
    const coastLo = makeStrokeRenderer(mesh.coastLo, coastStyle);
    const lakesLo = makeStrokeRenderer(mesh.lakesLo, lakesStyle);
    const riversLo = makeStrokeRenderer(mesh.riversLo, riverStyle);
    const coastHi = makeStrokeRenderer(mesh.coastHi, coastStyle);
    const lakesHi = makeStrokeRenderer(mesh.lakesHi, lakesStyle);
    const riversHi = makeStrokeRenderer(mesh.riversHi, riverStyle);
    const mapRendererLo = makeLayerRenderer([background]);
    const mapRendererHi = makeLayerRenderer([background]);
    const foregroundRendererLo = makeLayerRenderer([graticule, riversLo, lakesLo, coastLo, globe.foregroundRenderer()]);
    const foregroundRendererHi = makeLayerRenderer([graticule, riversHi, lakesHi, coastHi, globe.foregroundRenderer()]);

    function canvasDraw(isHD) {
        const mapRenderer = isHD ? mapRendererHi : mapRendererLo;
        const foregroundRenderer = isHD ? foregroundRendererHi : foregroundRendererLo;
        mapRenderer.renderTo(mapCtx, path);
        foregroundRenderer.renderTo(foregroundCtx, path);
        path.context(null);
    }

    function doDraw() {
        annotationSvg.selectAll("path").attr("d", path);
        canvasDraw(false);
        rendererAgent.call("redraw");
        doDraw_throttled = _.throttle(doDraw, REDRAW_WAIT, {leading: false});
    }

    // Attach to map rendering events on input controller.

    inputController.on("moveStart.renderer", () => {
        rendererAgent.call("start");
    });
    inputController.on("move.renderer", () => {
        doDraw_throttled();
    });
    inputController.on("moveEnd.renderer", () => {
        annotationSvg.selectAll("path").attr("d", path);
        canvasDraw(true);
        rendererAgent.call("render");
    });

    // Finally, inject the globe model into the input controller. Do it on the next event turn to ensure
    // renderer is fully set up before events start flowing.
    Promise.resolve().then(() => {
        inputController.globe(globe);
    }).catch(report.error);

    console.timeEnd("rendering map");
    return "ready";
}

function constructTaraElements(svg, tara, orientation, orient) {
    const g = svg.append("g");

    const plan = {type: "LineString", coordinates: tara.plan.coordinates.filter((e, i) => i > 253)};
    //g.append("path").attr("class", "tara-route tara-plan-border").datum(plan);
    g.append("path").attr("class", "tara-route tara-plan").datum(plan);

    let points = tara.track.geometries;
    points = points.filter(function(e, i) { return i > (points.length - 10) || i % 3 === 0; });

    const wholeTrack = {type: "LineString", coordinates: _.map(points, _.property("coordinates"))};
    const firstHalf = {type: "LineString", coordinates: wholeTrack.coordinates.slice(0, 3400)};
    const secondHalf = {type: "LineString", coordinates: wholeTrack.coordinates.slice(3400)};
    g.append("path").attr("class", "tara-route tara-track-border").datum(wholeTrack);
    g.append("path").attr("class", "tara-route tara-track-old").datum(firstHalf);
    g.append("path").attr("class", "tara-route tara-track").datum(secondHalf);

    const taraLoc = {type: "Point", coordinates: _.last(wholeTrack.coordinates), size: 4};
    if (orientation?.rotate === undefined) {
        orient(taraLoc.coordinates);
    }
    g.append("path").attr("class", "tara-loc").datum(taraLoc);

    const last = _.last(points);
    const data = {
        date: last["date"],
        heading: +(last["heading"] ?? NaN),
        speed: +(last["speed"] ?? NaN) * 3.6,
        air_temp: +(last["air_temp"] ?? NaN),
        water_temp: +(last["water_temp"] ?? NaN),
        pressure: +(last["pressure"] ?? NaN),
    };
    const fields = {
        date: utc.print(utc.localParts(data.date), "{YYYY}-{MM}-{DD} {hh}:{mm}"),
        heading: isNaN(data.heading) ? "N/A" : data.heading.toFixed(0),
        speed: isNaN(data.speed) ? "N/A" : data.speed.toFixed(1),
        air_temp: isNaN(data.air_temp) ? "N/A" : data.air_temp.toFixed(1),
        water_temp: isNaN(data.water_temp) ? "N/A" : data.water_temp.toFixed(1),
        pressure: isNaN(data.pressure) ? "N/A" : data.pressure.toFixed(0),
    };
    const div = d3.select("#tara-stats");
    div.selectAll("*").remove();
    div.append("p").text("Tara stats:");
    div.append("p").classed("stat", true)
        .text(fields.heading + "° @ " + fields.speed + " km/h");
    div.append("p").classed("stat", true).text(fields.air_temp + " °C (air)");
    div.append("p").classed("stat", true).text(fields.water_temp + " °C (water)");
    div.append("p").classed("stat", true).text(fields.pressure + " hPa");
    div.append("p").text(fields.date);
    div.selectAll(".stat").attr("style", "padding-left: 1em");
    div.classed("hidden", false);
}
