
import * as palette from "./palette.js";

export default function(resolution = 1000) {

    const bounds = [0, 1];  // units: kg/m^2
    const segments = [
        [0.0, [  5,   5,  89]],
        [0.2, [170, 170, 230]],
        [1.0, [255, 255, 255]],
    ];
    return palette.buildScaleFromSegments(bounds, segments, resolution);

}
