
import * as palette from "./palette.js";

export default function(resolution = 1000) {

    const bounds = [92000, 105000];  // units: Pa
    const segments = [
        [ 92000, [ 40,   0,   0]],
        [ 95000, [187,  60,  31]],
        [ 96500, [137,  32,  30]],
        [ 98000, [ 16,   1,  43]],
        [100500, [ 36,   1,  93]],
        [101300, [241, 254,  18]],
        [103000, [228, 246, 223]],
        [105000, [255, 255, 255]],
    ];
    return palette.buildScaleFromSegments(bounds, segments, resolution);

}
