
import * as palette from "./palette.js";

export default function(resolution = 1000) {

    const bounds = [0, 100];  // units: m/s
    const array = new Uint8Array(resolution * 4);
    palette.fillRange(array, [0, 1], [0, 1], palette.extendedSinebowColor);

    return palette.buildScale(bounds, array);

}
