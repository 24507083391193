
import * as palette from "./palette.js";
import kindlmann from "./kindlmann.js";

export default function() {

    const bounds = [0.0001, 3.0000];  // units: τ
    return palette.buildScale(bounds, kindlmann, Math.log, Math.exp);

}
