
import {ø, keep} from "../../util/objects.js";
import * as utc from "../../util/utc.js";
import * as clock from "../../util/clock.js";
import * as gaia from "../gaia.js";
import {scalarProduct} from "../scalarProduct.js";
import * as sources from "../sources.js";


const recipes = {
    sea_surface_temp:         {selector: /Temperature_surface_sparse/},
    sea_surface_temp_anomaly: {selector: /Temperature_surface_anomaly_sparse/},
};

export function rtgValidTime(time_cursor) {
    let parts;
    if (time_cursor === "current") {
        // Each day, SST data for the previous day is made available. So "current" means yesterday.
        const now = keep(utc.parts(clock.now()), "year", "month", "day");
        parts = utc.add(now, {day: -1});
    } else {
        parts = ø(time_cursor);
    }
    return parts;
}

/**
 * Returns a date for the chronologically next or previous RTG SST data layer. How far forward or backward in time
 * to jump is determined by the step. Steps of ±1 move in 1 day jumps, and steps of ±10 move in 5-day jumps.
 */
export function rtgStep(validTime, step) {
    const offset = step > 1 ? 5 : step < -1 ? -5 : step;
    return utc.add(validTime, {day: offset});
}

export function rtgPath(attr, name) {
    const isCurrent = attr.time_cursor === "current";
    const dir = isCurrent ? "current" : utc.print(attr.time_cursor, "{YYYY}/{MM}");
    const timestamp = isCurrent ? "current" : utc.print(attr.time_cursor, "{YYYY}{MM}{DD}");
    const file = `${timestamp}-${name}-rtg-0.5.epak`;
    return gaia.rtgsstUrl(`${dir}/${file}`);
}

export function rtgProduct(file, name) {
    const recipe = recipes[name];
    return Object.assign(scalarProduct(file, recipe.selector, {hasMissing: true}), {sourceHTML: sources.rtgsst});
}
