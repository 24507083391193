
import * as palette from "./palette.js";

export default function(resolution = 1000) {

    const bounds = [0, 100];  // units: percent
    const segments = [
        [  0, [230, 165,  30]],
        [ 25, [120, 100,  95]],
        [ 60, [ 40,  44,  92]],
        [ 75, [ 21,  13, 193]],
        [ 90, [ 75,  63, 235]],
        [100, [ 25, 255, 255]],
    ];
    return palette.buildScaleFromSegments(bounds, segments, resolution);

}
