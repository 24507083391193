
import * as utc from "../../util/utc.js";
import * as clock from "../../util/clock.js";
import * as gaia from "../gaia.js";
import {buildProduct} from "../productUtils.js";
import {tr} from "../../ux/translations.js";
import * as sources from "../sources.js";
import {scalarProduct} from "../scalarProduct.js";
import * as palettes from "../../palette/palettes.js";

function camsValidTime(time_cursor, stride) {
    const {year, month, day, hour} = time_cursor === "current" ? utc.parts(clock.now()) : time_cursor;
    return {year: year, month: month, day: day, hour: Math.floor(hour / stride) * stride, minute: 0};
}

function camsStep(validTime, stride, step) {
    const offset = (step > 1 ? 8 : step < -1 ? -8 : step) * stride;
    return utc.add(validTime, {hour: offset});
}

function camsPath(attr, layer, stride) {
    const validTime = camsValidTime(attr.time_cursor, stride);
    const isCurrent = attr.time_cursor === "current";
    const dir = isCurrent ? "current" : utc.print(validTime, "{YYYY}/{MM}/{DD}");
    const stamp = isCurrent ? "current" : utc.print(validTime, "{hh}{mm}");
    return gaia.camsUrl(`${dir}/${stamp}-${layer}-cams.epak`);
}

export function createPM1Layer(attr) {
    const stride = 1;
    return buildProduct({
        type: "pm1",
        descriptionHTML: {
            name: tr("Particulate Matter < 1 µm"),
            qualifier: ` @ ${tr("Surface")}`,
        },
        sourceHTML: sources.cams,
        paths: [camsPath(attr, "pm1", stride)],
        validTime: function() {
            return camsValidTime(attr.time_cursor, stride);
        },
        navigate: function(step) {
            return camsStep(this.validTime(), stride, step);
        },
        builder: function(file) {
            return scalarProduct(file, /pm1/, {hasMissing: false});
        },
        unitDescriptors: {
            "µg/m^3": {convert: x => x * 1e9, precision: 0},
        },
        alpha: {single: 160, animated: 140},
        scale: palettes.pmx(),
    });
}

export function createPM2p5Layer(attr) {
    const stride = 1;
    return buildProduct({
        type: "pm2.5",
        descriptionHTML: {
            name: tr("Particulate Matter < 2.5 µm"),
            qualifier: ` @ ${tr("Surface")}`,
        },
        sourceHTML: sources.cams,
        paths: [camsPath(attr, "pm2p5", stride)],
        validTime: function() {
            return camsValidTime(attr.time_cursor, stride);
        },
        navigate: function(step) {
            return camsStep(this.validTime(), stride, step);
        },
        builder: function(file) {
            return scalarProduct(file, /pm2p5/, {hasMissing: false});
        },
        unitDescriptors: {
            "µg/m^3": {convert: x => x * 1e9, precision: 0},
        },
        alpha: {single: 160, animated: 140},
        scale: palettes.pmx(),
    });
}

export function createPM10Layer(attr) {
    const stride = 1;
    return buildProduct({
        type: "pm10",
        descriptionHTML: {
            name: tr("Particulate Matter < 10 µm"),
            qualifier: ` @ ${tr("Surface")}`,
        },
        sourceHTML: sources.cams,
        paths: [camsPath(attr, "pm10", stride)],
        validTime: function() {
            return camsValidTime(attr.time_cursor, stride);
        },
        navigate: function(step) {
            return camsStep(this.validTime(), stride, step);
        },
        builder: function(file) {
            return scalarProduct(file, /pm10/, {hasMissing: false});
        },
        unitDescriptors: {
            "µg/m^3": {convert: x => x * 1e9, precision: 0},
        },
        alpha: {single: 160, animated: 140},
        scale: palettes.pmx(),
    });
}

/*
export function createCOLayer(attr) {
    const stride = 3;
    return buildProduct({
        type: "co",
        descriptionHTML: localize({
            name: {en: "CO", ja: ""},
            qualifier: ""
        }),
        sourceHTML: sources.cams,
        paths: [camsPath(attr, "co", stride)],
        validTime: function() {
            return camsValidTime(attr.time_cursor, stride);
        },
        navigate: function(step) {
            return camsStep(this.validTime(), stride, step);
        },
        builder: function(file) {
            return scalarProduct(file, /co|VAR192.*!/, {hasMissing: false, transform: data => {
                for (let i = 0; i < data.length; i++) {
                    data[i] *= 1e9;
                }
            }});
        },
        unitDescriptors: {  // CO Surface Concentration in ppbw, units: 1e-9
            "ppbw": {convert: x => x,        precision: 0},
            "ppmw": {convert: x => x / 1000, precision: 2},
        },
        alpha: {single: 160, animated: 140},
        scale: require("./palette/cosc")(),
    });
}

export function createO3Layer(attr) {
    const stride = 3;
    return buildProduct({
        type: "o3",
        descriptionHTML: localize({
            name: {en: "O3", ja: ""},
            qualifier: ""
        }),
        sourceHTML: sources.cams,
        paths: [camsPath(attr, "go3", stride)],
        validTime: function() {
            return camsValidTime(attr.time_cursor, stride);
        },
        navigate: function(step) {
            return camsStep(this.validTime(), stride, step);
        },
        builder: function(file) {
            return scalarProduct(file, /go3/, {hasMissing: false});
        },
        unitDescriptors: {
            "ppbw": {convert: x => x * 1e9, precision: 0},
        },
        alpha: {single: 160, animated: 140},
        scale: palette.buildScaleFromSegments([0, 600e-9], [
            [0,        [  0,   0,   0]],
            [600e-9,   [255, 255, 255]]
        ], 1000),
    });
}

export function createNO2Layer(attr) {
    const stride = 3;
    return buildProduct({
        type: "no2",
        descriptionHTML: localize({
            name: {en: "NO2", ja: ""},
            qualifier: ""
        }),
        sourceHTML: sources.cams,
        paths: [camsPath(attr, "no2", stride)],
        validTime: function() {
            return camsValidTime(attr.time_cursor, stride);
        },
        navigate: function(step) {
            return camsStep(this.validTime(), stride, step);
        },
        builder: function(file) {
            return scalarProduct(file, /no2/, {hasMissing: false});
        },
        unitDescriptors: {
            "ppbw": {convert: x => x * 1e9, precision: 0},
        },
        alpha: {single: 160, animated: 140},
        scale: palette.buildScaleFromSegments([0, 2000e-9], [
            [0,       [  0,   0,   0]],
            [2000e-9, [255, 255, 255]]
        ], 1000),
    });
}

export function createSO2Layer(attr) {
    const stride = 3;
    return buildProduct({
        type: "so2",
        descriptionHTML: localize({
            name: {en: "SO2", ja: ""},
            qualifier: ""
        }),
        sourceHTML: sources.cams,
        paths: [camsPath(attr, "so2", stride)],
        validTime: function() {
            return camsValidTime(attr.time_cursor, stride);
        },
        navigate: function(step) {
            return camsStep(this.validTime(), stride, step);
        },
        builder: function(file) {
            return scalarProduct(file, /so2/, {hasMissing: false});
        },
        unitDescriptors: {
            "µg/m^3": {convert: x => x * 1e9 * 2.86, precision: 2},
            "ppbw":   {convert: x => x * 1e9,        precision: 0},
        },
        alpha: {single: 160, animated: 140},
        scale: require("./palette/so2ppb")(),
    });
}
*/
