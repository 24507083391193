
import * as _ from "../lib/underscore.js";

import AirdensPalette from "./airdens.js";
import * as argo from "./argoPalettes.js";
import AuroraPalette from "./aurora.js";
import CO2Palette from "./co2.js";
import {variantA as CO2scPalette_A, variantB as CO2scPalette_B} from "./co2sc.js";
import COscPalette from "./cosc.js";
import CapePalette from "./cape.js";
import CurrentsPalette from "./currents.js";
import DuexttauPalette from "./duexttau.js";
import FGCO2 from "./fgco2.js";
import HTSGWPalette from "./htsgw.js";
import MSLPPalette from "./mslp.js";
import MiseryPalette from "./misery.js";
import PMxPalette from "./pmx.js";
import PrecipPalette from "./precip.js";
import PrimaryWavesPalette from "./primaryWaves.js";
import RHPalette from "./rh.js";
import SO2smassPalette from "./so2smass.js";
import SPCO2 from "./spco2.js";
import SSTAPalette from "./ssta.js";
import SSTPalette from "./sst.js";
import SuexttauPalette from "./suexttau.js";
import TCWPalette from "./tcw.js";
import TPWPalette from "./tpw.js";
import TempPalette from "./temp.js";
import WPDPalette from "./wpd.js";
import WindPalette from "./wind.js";

export const airdens = _.memoize(AirdensPalette);
export const argoMeanTemp = _.memoize(argo.meanTemp);
export const argoTempAnomaly = _.memoize(argo.tempAnomaly);
export const argoMeanSalinity = _.memoize(argo.meanSalinity);
export const argoSalinityAnomaly = _.memoize(argo.salinityAnomaly);
export const aurora = _.memoize(AuroraPalette);
export const cape = _.memoize(CapePalette);
export const co2 = _.memoize(CO2Palette);
export const co2sc_A = _.memoize(CO2scPalette_A);
export const co2sc_B = _.memoize(CO2scPalette_B);
export const cosc = _.memoize(COscPalette);
export const currents = _.memoize(CurrentsPalette);
export const duexttau = _.memoize(DuexttauPalette);
export const fgco2 = _.memoize(FGCO2);
export const htsgw = _.memoize(HTSGWPalette);
export const misery = _.memoize(MiseryPalette);
export const mslp = _.memoize(MSLPPalette);
export const pmx = _.memoize(PMxPalette);
export const precip = _.memoize(PrecipPalette);
export const primaryWaves = _.memoize(PrimaryWavesPalette);
export const rh = _.memoize(RHPalette);
export const so2smass = _.memoize(SO2smassPalette);
export const spco2 = _.memoize(SPCO2);
export const sst = _.memoize(SSTPalette);
export const ssta = _.memoize(SSTAPalette);
export const suexttau = _.memoize(SuexttauPalette);
export const tcw = _.memoize(TCWPalette);
export const temp = _.memoize(TempPalette);
export const tpw = _.memoize(TPWPalette);
export const wind = _.memoize(WindPalette);
export const wpd = _.memoize(WPDPalette);
