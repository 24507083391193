
import * as palette from "./palette.js";

export default function(resolution = 1000) {

    const bounds = [0, 5000];     // units: J/kg
    const segments = [            // Diverging 11-class RdBu, from colorbrewer2.org
        [   0, [  5,  48,  97]],  // weak
        [ 500, [ 33, 102, 172]],  // weak
        [1000, [ 67, 147, 195]],  // weak
        [1500, [146, 197, 222]],  // moderate
        [2000, [209, 229, 240]],  // moderate
        [2500, [247, 247, 247]],  // moderate
        [3000, [253, 219, 199]],  // strong
        [3500, [244, 165, 130]],  // strong
        [4000, [214,  96,  77]],  // strong
        [4500, [178,  24,  43]],  // extreme
        [5000, [103,   0,  31]]   // extreme
    ];
    return palette.buildScaleFromSegments(bounds, segments, resolution);

}
