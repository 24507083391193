
export const π = Math.PI;
export const τ = 2 * π;
export const DEG = 360 / τ;
export const RAD = τ / 360;

export const MILLI = 1;
export const SECOND = 1000 * MILLI;
export const MINUTE = 60 * SECOND;

export const NIL = 7e37;
