
let next = 0;

function replace(s) {
    return s.replace(/\?/g, () => next++);
}

/**
 * A string template processor that works like a normal string template but replaces "?" characters with an
 * auto-incrementing value.
 *
 * <pre>
 *     seq`a`          // "a"
 *     seq`a?`         // "a0"
 *     seq`a?`         // "a1"
 *     seq`a??`        // "a23"
 *     seq`a?${"b"}?`  // "a4b5"
 *     seq`?`          // "6"
 * </pre>
 *
 * @param {string[]} parts
 * @param [substitutions]
 * @returns {string} the replaced string
 */
export function seq(parts, ...substitutions) {
    let result = replace(parts[0]);
    for (let i = 0; i < substitutions.length; i++) {
        result += substitutions[i] + replace(parts[i + 1]);
    }
    return result;
}
