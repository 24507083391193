
import * as palette from "./palette.js";

export default function(resolution = 10000) {

    const bounds = [0, 80000];  // units: W/m^2. CONSIDER: convert to log scale
    const segments = [
        [    0, [ 15,   4,  96]],
        [  250, [ 30,   8, 180]],
        [ 1000, [121, 102,   2]],
        [ 2000, [118, 161,  66]],
        [ 4000, [ 50, 102, 219]],
        [ 8000, [ 19, 131, 193]],
        [16000, [ 59, 204, 227]],
        [64000, [241,   1,  45]],
        [80000, [243,   0, 241]],
    ];
    return palette.buildScaleFromSegments(bounds, segments, resolution);

}
