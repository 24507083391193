/**
 * An object to display various types of messages to the user.
 */

import * as d3 from "../lib/d3.js";

const REMAINING = "▫▫▫▫▫▫▫▫▫▫▫▫▫▫▫▫▫▫▫▫";  // glyphs for remaining progress bar
const COMPLETED = "▪▪▪▪▪▪▪▪▪▪▪▪▪▪▪▪▪▪▪▪";  // glyphs for completed progress bar

export function buildReporter(bridge) {

    // Route all messages to bridge if supported.
    const s = d3.select("#status"), p = d3.select("#progress"), total = REMAINING.length;
    const showMessage = bridge.showMessage?.bind(bridge) ?? function(msg/*, isError*/) { s.text(msg); };
    const showProgress = bridge.showProgress?.bind(bridge) ?? function(amount) {  // amount must be int
        if (0 <= amount && amount < 100) {
            const i = Math.ceil(amount / 100 * total);
            const bar = COMPLETED.substr(0, i) + REMAINING.substr(0, total - i);
            return p.classed("hidden", false).text(bar);
        }
        return p.classed("hidden", true).text("");  // progress complete
    };

    return {
        status: function(msg) {
            return s.classed("bad") ? s : showMessage(msg, false);  // errors are sticky until reset
        },
        error: function(err, context) {
            let msg = err.status ? `${err.status} ${err.message}` : (err.message || err);
            switch (err.status) {
                case -1: msg = "Server Down"; break;
                case 404: msg = "No Data"; break;
            }
            if (context) {
                console.error("context", context+"");
            }
            console.error("error", err);
            if (err.stack) {
                console.error("stack", err.stack);
            }
            s.classed("bad", true);
            showMessage(msg, true);
        },
        reset: function() {
            s.classed("bad", false);
            showMessage("", false);
        },
        progress: showProgress
    };
}
